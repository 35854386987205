import "./aaaHeader-styles.scss";
import { useToggle } from "../../../../hooks/useToggle/useToggle";
import { IFrameContainer } from "../../../Global/ScheduleACall/ScheduleACall";

export const AAAHeader = ({ title, description }) => {
  const [state, toggle] = useToggle();

  return (
    <div className="c-aaa-header">
      <h1 className="aaa-header-heading">{title}</h1>
      <h3 className="aaa-hourly-rates">{description}</h3>
      <button className="aaa-header-consultancy-btn" onClick={toggle}>
        Free Consultation
      </button>
      {state && <IFrameContainer state={state} toggle={toggle} />}
    </div>
  );
};
