import "./aloWhyBrandLoveUs-styles.scss";
import { BsCheckAll } from "react-icons/bs";
import AloRight from "../../../../static/alo-right.png";

export const ALOWhyBrandLoveUs = () => {
  return (
    <div className="c-ALO-why-brand-love-us" style={{ alignItems: "center" }}>
      <div className="ALO-left">
        <h1 className="brand-love-us-heading">Why Biz Spartan? </h1>
        <p className="brand-love-us-description">
          It is because we have what it takes to make your products get noticed
          by customers online. We offer:{" "}
        </p>
        <div className="reason-list">
          <div className="reason">
            <p className="list-icon">
              <BsCheckAll />
            </p>
            <p className="reason-text">
              Unique Product Titles, Product Descriptions, and
              Easy-to-Understand Content Structure
            </p>
          </div>
          <div className="reason">
            <p className="list-icon">
              <BsCheckAll />
            </p>
            <p className="reason-text">
              Research-Based Keywords & SEO Expertise
            </p>
          </div>
          <div className="reason">
            <p className="list-icon">
              <BsCheckAll />
            </p>
            <p className="reason-text">Creative Sales Copywriters</p>
          </div>
          <div className="reason">
            <p className="list-icon">
              <BsCheckAll />
            </p>
            <p className="reason-text">Professional Graphic Designers</p>
          </div>
        </div>
      </div>
      <div className="ALO-right">
        <img src={AloRight} style={{ width: "100%" }} />
      </div>
    </div>
  );
};
