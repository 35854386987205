import "./founderStatement-styles.scss";
import { FaQuoteLeft } from "react-icons/fa";
import BusinessManImage from "../../../static/Businessman.png";
export const FounderStatement = () => {
  return (
    <div className="c-founder-statement">
      <div class="inner-container">
        <h1 id="heading">What Our Founder Motive?</h1>
        <div className="statement-container">
          <p className="statement">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
        </div>
      </div>
    </div>
  );
};
