export const optimizationAudit = {
  name: "Optimization Audit",
  left: {
    heading:
      "Want To Increase Your Sales & Know How Your Amazon Store is Doing?",
    text_description:
      "Get in touch with us and allow our Amazon experts to analyze your account. We’ll let you know what’s working and what needs adjustment!",
  },
  audit_form: {
    form_title: "Have Questions? Contact Us Today!",
    form_description:
      "We are available round the clock to address your queries.",
    form_fields: [
      {
        label: "URL or ASIN",
        field_setting: {
          type: "text",
          name: "ASIN",
          placeholder: "Type Product URL or ASIN",
        },
        field_content_type: "short",
        col_span: 2,
      },
      {
        label: "Email",
        field_setting: {
          type: "email",
          name: "email",
          placeholder: "Type Your Email",
        },
        field_content_type: "short",
        col_span: 1,
      },
      {
        label: "Company",
        field_setting: {
          type: "text",
          name: "company",
          placeholder: "Type Your Company Name",
        },
        field_content_type: "short",
        col_span: 1,
      },
      {
        label: "Message",
        field_setting: {
          type: "text",
          name: "message",
          placeholder: "Type Your Message",
        },
        field_content_type: "long",
        col_span: 2,
      },
    ],
  },
};
