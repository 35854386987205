import "./why-important-style.scss";
import { product_ranking_data } from "../../../../data";
import keywordRankPart from "../../../../static/keyword-rank-part.png";

export const WhyRankingImportant = () => {
  const { title, description, question, videoBtn } =
    product_ranking_data.why_ranking_important;
  return (
    <>
      <section className="c_why_important_ranking">
        <div className="parent_why_important_sec">
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
          <div></div>
        </div>
      </section>
      <section className="keywordRankPartSec">
        <img src={keywordRankPart} className="keywordRankPartImg" />
      </section>
    </>
  );
};
