import "./product-ranking-style.scss";
import { product_ranking_data } from "../../../../data";
import { IFrameContainer } from "../../../Global/ScheduleACall/ScheduleACall";
import { useToggle } from "../../../../hooks/useToggle/useToggle";

export const KeywordRanking = () => {
  const [state, toggle] = useToggle();

  const {
    product_title,
    product_tagline,
    product_question,
    product_advantages,
    consultancy,
  } = product_ranking_data.product_ranking_header;
  return (
    <section className="c_product_ranking">
      <div className="ranking_details">
        <h1 className="ranking_title">
          <strong>RANK YOUR PRODUCT ON</strong>{" "}
          <span className="amazon">AMAZON </span>
        </h1>
        <h2 className="ranking_tagline">{product_tagline}</h2>
        <div className="ranking_benefits">
          <p className="ranking_question">{product_question}</p>
        </div>
        <div>
          {consultancy.map((data) => {
            return (
              <div>
                <h4 className="consultancy_tagline">
                  {data.consultancyTagline}
                </h4>
                <div className="btnpart">
                  <a href="/contact-us" className="get_a_quote">
                    {data.get_a_quote}
                  </a>
                  <a onClick={toggle} className="book_a_call">
                    {data.book_a_call}
                  </a>
                  {state && <IFrameContainer state={state} toggle={toggle} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
