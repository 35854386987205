import {
  Amazon3DRenderingHeader,
  HowItWorks1,
  ServicesSection3D,
  WhyLove3D,
  Faqs3D,
  SliderCarousel,
  BringProducts,
  ScheduleACall,
} from "../components";

import { Amazon3drendering_data } from "../data";

const Amazon3d = () => {
  const { how_does_it_work_content, FAQs_section } = Amazon3drendering_data;
  return (
    <div className="c_3d_container">
      <Amazon3DRenderingHeader />
      <ServicesSection3D />
      <WhyLove3D />
      <BringProducts />
      <HowItWorks1
        title={how_does_it_work_content.title}
        description={how_does_it_work_content.description}
        steps={how_does_it_work_content.steps}
      />
      <Faqs3D />
      <SliderCarousel />
      <ScheduleACall />
    </div>
  );
};
export default Amazon3d;
