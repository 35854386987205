import "./servicesCard-styles.scss";
import { useNavigate } from "react-router-dom";

export const ServicesCard = ({ services }) => {
  const navigate = useNavigate();

  return (
    <>
      <section className="c-our-services-section">
        <div className="container">
          <div className="parent-box">
            {services.map((data, key) => {
              return (
                <div
                  className="grid-item"
                  key={key}
                  onClick={() => {
                    navigate(data.card_link);
                  }}
                >
                  <h2>{data.card_serial}</h2>
                  <h3>{data.card_title}</h3>
                  <p>{data.card_description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
