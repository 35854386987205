import "./aboutservice-style.scss";

import { product_photography_data } from "../../../../data";

export const AboutService = () => {
  const { title, about_description, cameraImage } =
    product_photography_data.about_service_data;
  return (
    <section className="c_main_about_service">
      <div className="parent_about_service">
        <div className="parent_image_about_service">
          <img className="camera_image" src={cameraImage} />
        </div>

        <div className="parent_about_service_description">
          <h2 className="camera_title">{title}</h2>

          {about_description.map((data) => {
            return <p className="camera_description">{data.description}</p>;
          })}
        </div>
      </div>
    </section>
  );
};
