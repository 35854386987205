export const nav_data = {
  website_title: "Amazon Web",
  logo: "",
  nav_links: [
    {
      name: "Home",
      link: "/",
      is_nested_link: false,
      is_cta_btn: false,
    },
    {
      name: "Services",
      is_nested_link: true,
      is_cta_btn: false,
      nested_links: [
        {
          name: "Amazon Product Photography",
          link: "/services/amazon-product-photography",
        },
        {
          name: "Amazon Product Videography",
          link: "/services/amazon-product-videography",
        },
        {
          name: "Amazon listing Optimization",
          link: "/services/amazon-listing-optimization",
        },
        {
          name: "Amazon PPC Management",
          link: "/services/amazon-ppc-management",
        },
        {
          name: "A+ Content/EBC Service",
          link: "/services/ebc-service",
        },
        {
          name: "Amazon Account Management",
          link: "/services/amazon-account-management",
        },
        {
          name: "Amazon 3D Rendering",
          link: "/services/amazon-3d-rendering",
        },
        {
          name: "Amazon Keyword Ranking",
          link: "/services/amazon-keyword-ranking",
        },
        {
          name: "Amazon Account Audit",
          link: "/services/amazon-account-audit",
        },
      ],
    },
    {
      name: "About Us",
      is_cta_btn: false,
      is_nested_link: false,
      link: "/about-us",
    },
    {
      name: "Contact Us",
      is_nested_link: false,
      link: "/contact-us",
      is_cta_btn: false,
    },
    {
      is_cta_btn: true,
      is_nested_link: false,
      name: "Get Quote",
      link: "/contact-us",
    },
  ],
};
