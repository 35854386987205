import "./3DServices-style.scss";

import Fancybox from "../../../Global/FancyBox/Fancybox";

import { Amazon3drendering_data } from "../../../../data";

export const ServicesSection3D = () => {
  const { title, description, images } =
    Amazon3drendering_data.product_portfolio_3d;
  return (
    <section className="c_3d_services">
      <div className="parent_description">
        <h2 className="main_portfolio_title">{title}</h2>
        <p className="main_portfolio_description">{description}</p>
      </div>

      <div className="fancyBox_section">
        <Fancybox>
          <div className="parent_gallery_images">
            {images.map((data) => {
              return (
                <p className="main_parent_image">
                  <a data-fancybox="gallery" href={data.images}>
                    <img alt="" src={data.images} />
                  </a>
                </p>
              );
            })}
          </div>
        </Fancybox>
      </div>
    </section>
  );
};
