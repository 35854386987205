import { BsCheckLg } from "react-icons/bs";
import firstoneImg from "../../static/d-1.jpg";
import firsttwo from "../../static/d-2.jpg";
import firstthree from "../../static/d-3.jpg";
import firstfour from "../../static/d-4.jpg";
import firstfive from "../../static/d-5.jpg";
import firstsix from "../../static/d-6.jpg";
import firstseven from "../../static/d-7.jpg";
import firsteight from "../../static/d-8.jpg";
import firstnine from "../../static/d-9.jpg";

import { TbFriends, TbDeviceDesktop, TbHome, TbPackage } from "react-icons/tb";
import bringProducts from "../../static/3d-section-opt.webp";

export const Amazon3drendering_data = {
  amazon_3d_header: {
    name: "3dRendering",
    title: "Amazon 3D Product Rendering Service",
    tagline:
      "Schedule a free consultation call with one of our experts to discuss your Amazon 3D Product Rendering Service.",
    get_a_quote: "Get a Quote",
    schedule_call: "Schedule a Call",
    services_content: [
      {
        icon: <BsCheckLg />,
        description: "3D Modeling",
      },
      {
        icon: <BsCheckLg />,
        description: "Get Unlimited Product Angles",
      },
      {
        icon: <BsCheckLg />,
        description: "Crystal Clear Photos",
      },
      {
        icon: <BsCheckLg />,
        description: "Stand Out From Your Competitors",
      },
    ],
  },
  product_portfolio_3d: {
    title: "Our Product 3D Rendering Services",
    description:
      "We specialize in creating images that tell your brand's story. Using 3D Rendering Software, we can model your product and create renders that are indistinguishable from actual photos. Additionally, our 3D renders are also cost-effective and can be easily changed at any time.",
    images: [
      {
        images: firstoneImg,
      },
      {
        images: firsttwo,
      },
      {
        images: firstthree,
      },
      {
        images: firstfour,
      },
      {
        images: firstfive,
      },
      {
        images: firstsix,
      },
      {
        images: firstseven,
      },
      {
        images: firsteight,
      },
      {
        images: firstnine,
      },
    ],
  },
  why_brand_love_us_3d: {
    title: "Why Brands Love Us",
    description:
      "Leading rating & review platforms rank AMZ One Step among top Product Photography companies",
    whyloveusbenefits: [
      {
        icon: <BsCheckLg />,
        benefits: "60,000+ Amazon Images Created",
      },
      {
        icon: <BsCheckLg />,
        benefits: "Studios in US, UK, China and Canada",
      },
      {
        icon: <BsCheckLg />,
        benefits: "Dedicated Account Manager",
      },
      {
        icon: <BsCheckLg />,
        benefits: "Worked With 3000+ Brands",
      },
      {
        icon: <BsCheckLg />,
        benefits: "In-house Studio With Lifestyle Settings",
      },
    ],
  },
  how_does_it_work_content: {
    name: "HowDoesItWork",
    title: "How It Works ?",
    description: "",
    steps: [
      {
        id: 1,
        name: "WeGather",
        title: "We Gather",
        icon: <TbFriends />,
        description:
          "Our team of specialists is committed to producing the best results in order to make your product a success.",
      },
      {
        id: 2,
        name: "WeMake",
        title: "We Make",
        icon: <TbDeviceDesktop />,
        description:
          "Our experts know how important market research is! No work is done without extensive research of market trends.",
      },
      {
        id: 3,
        name: "WeDeveloped",
        title: "We Developed",
        icon: <TbHome />,
        description:
          "The quality of our work and your time are both of tremendous importance to us. We deliver the best results promptly!",
      },
      {
        id: 4,
        name: "WeDeveloped",
        title: "We Developed",
        icon: <TbPackage />,
        description:
          "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.!",
      },
    ],
  },
  FAQs_section: [
    {
      label: "What is your services?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "Are you people working on Amazon?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "What is the difference between dropshipping and PL ?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "Can you please explain us FBM?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "Give me some demo of your work.",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "In which business you are working on?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
  ],
  one_step_difference_section_3d: {
    title: "Bring your products to life with our 3D Rendering Images",
    description:
      "High-quality marketing is essential in today’s competitive business world. It is no secret that product images are an essential part of that. A single photograph can provide volumes of information that the consumer can understand quicker. Using our software, we can create realistic product 3d model images even for products that might not exist. Similarly, if the product you are selling has multiple color variations on Amazon 3D rendering your product will be an extremely cost-effective alternative to traditional product photography. Once a 3D product rendering has been produced, it can then be easily turned into an animation, which can have a significant impact on the engagement of a product listing featuring 3D animation.",
    bringProductsImg: bringProducts,
  },
};
