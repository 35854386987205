import "./faqs-ebc-style.scss";
import { Accordion } from "../../../Global/Accordion/Accordion";

import { amazon_a_content_data } from "../../../../data/amazon_a_content/amazon_a_content";

export const EbcContentFaqs = () => {
  const { FAQs_section } = amazon_a_content_data;
  return (
    <section className="ebc-faqs">
      <Accordion accordionData={FAQs_section} />
    </section>
  );
};
