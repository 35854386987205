import "./header-styles.scss";

export const Header = () => {
  return (
    <div className="c-header">
      <div className="header-content">
        <h1>
          Optimizing Your Amazon Page To Profitable{" "}
          <span id="highlight">Heights!</span>
        </h1>
        <p id="header-about">
          In the ever-growing market of Amazon, the only way to stand apart from
          the competition is through optimized listings. Biz Spartan offers you
          data-driven optimization strategies that convert potential customers
          to guaranteed buyers.
        </p>
      </div>
    </div>
  );
};
