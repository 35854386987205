import "./faq-keyword-style.scss";
import { Accordion } from "../../../Global/Accordion/Accordion";

import { product_ranking_data } from "../../../../data";

export const ProductKeywordFaqs = () => {
  const { FAQs_section } = product_ranking_data;
  return (
    <section className="keyword_faqs">
      <Accordion accordionData={FAQs_section} />
    </section>
  );
};
