import {
  KeywordRanking,
  WhyRankingImportant,
  SliderCarousel,
  HowItWorks1,
  ProductKeywordFaqs,
  ScheduleACall,
} from "../components";
import { product_ranking_data } from "../data";

const OurKeyworkRanking = () => {
  const { how_does_it_work_content, FAQs_section } = product_ranking_data;
  return (
    <div className="c_sercive_container">
      <KeywordRanking />
      <WhyRankingImportant />
      <HowItWorks1
        title={how_does_it_work_content.title}
        description={how_does_it_work_content.description}
        steps={how_does_it_work_content.steps}
      />
      <SliderCarousel />
      <ProductKeywordFaqs />
      <ScheduleACall />
    </div>
  );
};

export default OurKeyworkRanking;
