import ListingCreatedIcon from "../../static/listings-created.png";
import AccountsManagedIcon from "../../static/accounts-managed.png";
import CampaignsManagedIcon from "../../static/campaigns-managed.png";

export const projectsDoneCounter = {
  name: "Projects Done Counter",
  title: "Our Stats Speak For Themselves!",
  project_categories: [
    {
      id: 0,
      name: "Listings Created",
      projects_done_count: "500+",
      icon: ListingCreatedIcon,
      description:
        "We have helped over 500 products reign on top of the first page through industry knowledge, keyword-rich descriptions, and best product titles.",
    },
    {
      id: 1,
      name: "Accounts Managed",
      projects_done_count: "50+",
      icon: AccountsManagedIcon,
      description:
        "We have successfully managed over 50 Amazon accounts, maintained their ROI goals, and delivered flawless reports.",
    },
    {
      id: 2,
      name: "Campaigns Managed",
      projects_done_count: "250+",
      icon: CampaignsManagedIcon,
      description:
        "Our PPC and Marketing gurus have empowered several Amazon businesses of all niches through potent marketing and copywriting strategies.",
    },
  ],
};
