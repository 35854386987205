import "./ppcService-styles.scss";

export const PPCService = ({ servicesData }) => {
  const { title, services_card_data } = servicesData;
  return (
    <div className="c-ppc-service">
      <h1 className="ppc-service-heading">{title}</h1>
      <div className="ppc-service-container">
        {services_card_data &&
          services_card_data?.map((serviceCardData) => {
            return <PPCServiceCards cardData={serviceCardData} />;
          })}
      </div>
    </div>
  );
};

const PPCServiceCards = ({ cardData }) => {
  const { title, description } = cardData;
  return (
    <div className="c-ppc-service-card">
      <div className="ppc-card-top">
        <h1>{title}</h1>
      </div>
      <div className="ppc-card-bottom">
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
