import { Route, Routes as RoutesWrapper } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import OurKeyworkRanking from "./pages/KeyworkRanking";
import { Our_Amazon_Product_Photography } from "./pages/ProductPhotography";
import AmazonListingOptimization from "./pages/AmazonListingOptimization";
import AmazonProductVideography from "./pages/AmazonProductVideography";
import AmazonAccountManagement from "./pages/AmazonAccountManagement";
import AmazonPPCManagement from "./pages/AmazonPPCManagement";
import AmazonA_Content from "./pages/AmazonA+Content";
import AmazonAccountAudit from "./pages/AmazonAccountAudit";
import Amazon3d from "./pages/Amazon3dRendering";
const Routes = () => {
  return (
    <RoutesWrapper>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route
        path="/services/amazon-keyword-ranking"
        element={<OurKeyworkRanking />}
      />
      <Route
        path="/services/amazon-product-photography"
        element={<Our_Amazon_Product_Photography />}
      />
      <Route
        path="/services/amazon-listing-optimization"
        element={<AmazonListingOptimization />}
      />
      <Route
        path="/services/amazon-product-videography"
        element={<AmazonProductVideography />}
      />
      <Route
        path="/services/amazon-account-management"
        element={<AmazonAccountManagement />}
      />
      <Route
        path="/services/amazon-ppc-management"
        element={<AmazonPPCManagement />}
      />
      <Route path="/services/ebc-service" element={<AmazonA_Content />} />
      <Route
        path="/services/amazon-account-audit"
        element={<AmazonAccountAudit />}
      />
      <Route path="/services/amazon-3d-rendering" element={<Amazon3d />} />
    </RoutesWrapper>
  );
};

export default Routes;
