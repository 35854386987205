import {
  AboutUsHeader,
  AboutUsSection,
  HowWeWork,
  FounderStatement,
} from "../components";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <AboutUsHeader />
      <AboutUsSection />
      <HowWeWork />
      <FounderStatement />
    </div>
  );
};

export default AboutUs;
