import "./A-our-portfolio-style.scss";
import { amazon_a_content_data } from "../../../../data";

export const A_OurPortfolio = () => {
  const { title, description, ebcportfolioImg } =
    amazon_a_content_data.ebc_ourPortfolio_data;
  return (
    <section className="c_amazon_ebc_portfolio_section">
      <div className="parent_ebc_portfolio_description">
        <h2 className="title">{title}</h2>
        <p className="desc">{description}</p>
      </div>

      <div className="parent_ebc_portfolio_image">
        <img className="images" src={ebcportfolioImg} />
      </div>
    </section>
  );
};
