import "./optimizationAudit-styles.scss";
import { OptimizationAuditProductForm } from "./OptimizationAuditProductForm/OptimizationAuditProductForm";
import { optimizationAudit } from "../../../data";

export const OptimizationAudit = () => {
  const {
    left: { heading, text_description },
    audit_form,
  } = optimizationAudit;
  return (
    <div className="c-optimization-audit">
      <div className="inner-wrapper">
        <div className="left">
          <div className="hr"></div>
          <h2 className="section-title">{heading}</h2>
          <p className="section-description">{text_description}</p>
        </div>
        <div className="right">
          <OptimizationAuditProductForm formData={audit_form} />
        </div>
      </div>
    </div>
  );
};
