import "./EligibilityContent.scss";
import { amazon_a_content_data } from "../../../../data";

export const EligibilityContent = () => {
  const {
    title,
    description,
    elligibilty_heading,
    eligibilty_description,
    eligibillty_options_first,
    eligibillty_options_second,
    why_us_heading,
    why_us_para,
    why_options,
    eligibilityImg,
  } = amazon_a_content_data.eligibilty_criteria;
  return (
    <section className="c_main_eligibility">
      <div className="parent_about_eligibility">
        <div className="parent_about_eligibility_description">
          <h2 className="camera_title">{title}</h2>
          <p className="camera_description">{description}</p>

          <div className="parent_eligibility_head">
            <h3>{elligibilty_heading}</h3>
            <p>{eligibilty_description}</p>
            <ol className="ordered_criteria">
              <li>{eligibillty_options_first}</li>
              <li>{eligibillty_options_second}</li>
            </ol>
          </div>

          <div className="parent_why_us">
            <h3>{why_us_heading}</h3>
            <p>{why_us_para}</p>
            <ul className="un_ordered_criteria">
              {why_options.map((data) => {
                return <li>{data.options}</li>;
              })}
            </ul>
          </div>
        </div>

        <div className="parent_image_eligibilty">
          <img className="camera_image" src={eligibilityImg} />
        </div>
      </div>
    </section>
  );
};
