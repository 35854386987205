import "./aamInventoryManagement-styles.scss";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export const AAMInventoryManagement = ({ inventoryManagementData }) => {
  return (
    <div className="c-aam-inventory-management">
      <div className="im-inner-container">
        <div className="inventory-management-header">
          <h1 className="im-heading">{inventoryManagementData?.title}</h1>
          <p className="im-description">
            {inventoryManagementData?.description}
          </p>
        </div>
        <div className="im-grid">
          {!!inventoryManagementData?.management_steps.length &&
            inventoryManagementData?.management_steps.map((step) => {
              return <IMCard cardData={step} />;
            })}
        </div>
      </div>
    </div>
  );
};

const IMCard = ({ cardData }) => {
  return (
    <div className="c-im-card">
      <h3 className="im-card-heading">{cardData?.title}</h3>
      <hr className="im-hr" />
      <p className="im-card-description">{cardData?.description}</p>

      {!!cardData?.sub_steps.length &&
        cardData?.sub_steps.map((subStep) => {
          return (
            <div className="im-list">
              {subStep?.for === "Suppliers" && (
                <h4 className="im-for-label">For Suppliers</h4>
              )}
              {subStep?.for === "Customers" && (
                <h4 className="im-for-label">For Customers</h4>
              )}
              <div className="im-inner-list">
                <div className="list-item">
                  <span className="list-icon">
                    <MdOutlineKeyboardArrowRight />
                  </span>
                  <p className="list-text">Product ID & Prices.</p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
