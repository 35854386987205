import "./WhyBrandLoveUs-style.scss";
import { product_photography_data } from "../../../../data";

import love_us_brand from "../../../../static/brand-love.jpg";

export const WhyBrandLoveUs = () => {
  const { title, description, whyloveusbenefits } =
    product_photography_data.why_brand_love_us;
  return (
    <section className="c_photography_why_brand_love_us">
      <div className="parent_photgraphy_why_brand_love_us">
        <div className="why_brand_love_us_description">
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>

          <div>
            <ul className="photography_whybrand_listing">
              {whyloveusbenefits.map((data) => {
                return (
                  <li>
                    {" "}
                    {data.icon} <span> {data.benefits} </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="why_brand_love_us_image">
          <img src={love_us_brand} />
        </div>
      </div>
    </section>
  );
};
