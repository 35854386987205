import {
  A_Header,
  A_OurPortfolio,
  HowItWorks1,
  SliderCarousel,
  EbcContentFaqs,
  ScheduleACall,
  EligibilityContent,
} from "../components";
import { amazon_a_content_data } from "../data";

const AmazonA_Content = () => {
  const { FAQs_section, how_does_it_work_content } = amazon_a_content_data;
  return (
    <div className="amazon-a-container">
      <A_Header />
      <A_OurPortfolio />
      <EligibilityContent />
      <HowItWorks1
        title={how_does_it_work_content.title}
        description={how_does_it_work_content.description}
        steps={how_does_it_work_content.steps}
      />
      <SliderCarousel />
      <EbcContentFaqs />
      <ScheduleACall />
    </div>
  );
};

export default AmazonA_Content;
