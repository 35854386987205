import "./product-portfolio-style.scss";

import Fancybox from "../../../Global/FancyBox/Fancybox";
import { product_photography_data } from "../../../../data";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export const ProductPortfolio = () => {
  const { title, description, white_background, life_style, infp_graphics } =
    product_photography_data.product_portfolio;
  return (
    <section className="c_portfolio_section">
      <div className="parent_description">
        <h2 className="main_portfolio_title">{title}</h2>
        <p className="main_portfolio_description">{description}</p>
      </div>

      <Tabs>
        <TabList>
          <Tab>
            <h2>{white_background.name}</h2>
          </Tab>
          <Tab>
            <h2>{life_style.name}</h2>
          </Tab>
          <Tab>
            <h2>{infp_graphics.name}</h2>
          </Tab>
        </TabList>

        <TabPanel>
          <Fancybox>
            <div className="parent_gallery_images">
              {white_background.first_images.map((data) => {
                return (
                  <p className="main_parent_image">
                    <a data-fancybox="gallery" href={data.images}>
                      <img alt="" src={data.images} />
                    </a>
                  </p>
                );
              })}
            </div>
          </Fancybox>
        </TabPanel>

        <TabPanel>
          <Fancybox>
            <div className="parent_gallery_images">
              {life_style.first_images.map((data) => {
                return (
                  <p className="main_parent_image">
                    <a data-fancybox="gallery" href={data.images}>
                      <img alt="" src={data.images} />
                    </a>
                  </p>
                );
              })}
            </div>
          </Fancybox>
        </TabPanel>

        <TabPanel>
          <Fancybox>
            <div className="parent_gallery_images">
              {infp_graphics.first_images.map((data) => {
                return (
                  <p className="main_parent_image">
                    <a data-fancybox="gallery" href={data.images}>
                      <img alt="" src={data.images} />
                    </a>
                  </p>
                );
              })}
            </div>
          </Fancybox>
        </TabPanel>
      </Tabs>
    </section>
  );
};
