import "./navbar-styles.scss";
import { nav_data } from "../../../data";
import { RiMenu4Fill } from "react-icons/ri";
import { useToggle } from "../../../hooks/useToggle/useToggle";
import { Link } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Logo from '../../../static/main_logo.png';

const Sidebar = ({ state, toggler, data }) => {
  const navigate = useNavigate();
  return (
    <div id="myNav" className="overlay" style={{ width: state && "100%" }}>
      <a href="#" className="close-btn" onClick={toggler}>
        &times;
      </a>

      <div className="overlay-content">
        <MenuList nav_links={data} toggler={toggler} />
      </div>
    </div>
  );
};

const DropDownList = ({ nav_link_name, nested_links, toggler }) => {
  const navigate = useNavigate();
  return (
    <div className="dropdown">
      <button className="nav-link">
        {nav_link_name}
        <span className="dropdown-icon">
          <AiFillCaretDown />
        </span>
      </button>
      <div className="dropdown-content">
        {nested_links.map((nested_link, nested_key) => {
          return (
            <Link
              to={nested_link.link}
              key={nested_key}
              onClick={() => {
                toggler();
              }}
            >
              {nested_link.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const NavCTAButton = ({ name, link = "#" }) => {
  return (
    <Link className="nav-link cta-button" to={link}>
      {name}
    </Link>
  );
};

const MenuList = ({ nav_links, toggler }) => {
  const navigate = useNavigate();
  return (
    <ul className="nav-links">
      {nav_links.map((nav_link, key) => {
        if (nav_link.is_nested_link) {
          return (
            <li className="nav-item" key={key}>
              <DropDownList
                nav_link_name={nav_link.name}
                nested_links={nav_link.nested_links}
                toggler={toggler}
              />
            </li>
          );
        } else if (nav_link.is_cta_btn) {
          return (
            <li className="nav-item" key={key}>
              <NavCTAButton name={nav_link.name} link={nav_link.link} />
            </li>
          );
        }
        return (
          <li
            className="nav-item nav-link"
            key={key}
            onClick={() => {
              navigate(nav_link.link);
              toggler();
            }}
          >
            <Link className="nav-link" to={nav_link.link}>
              {nav_link.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export const Navbar = () => {
  const { website_title, logo, nav_links } = nav_data;

  const [state, toggle] = useToggle(false);

  return (
    <div className="c-navbar">
      <div className="nav-mobile">
        <div className="nav-icon" onClick={toggle}>
          <RiMenu4Fill />
        </div>
        <Sidebar state={state} toggler={toggle} data={nav_links} />
      </div>
      <div className="logo-container">
        {/* <h4 id="logo">{Logo}</h4> */}
        <img id="logo" src={Logo} height={70} width={130}/>
      </div>
      <div className="nav">
        <MenuList nav_links={nav_links} />
      </div>
    </div>
  );
};
