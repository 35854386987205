import {
  OurServices,
  Header,
  ProjectsDoneCounter,
  OptimizationAudit,
  SliderCarousel,
  ScheduleACall,
} from "../components";

const Home = () => {
  return (
    <div className="home-container">
      <Header />
      <OurServices />
      <ProjectsDoneCounter />
      <OptimizationAudit />
      <SliderCarousel />
      <ScheduleACall />
    </div>
  );
};

export default Home;
