import "./aaaDescription-styles.scss";

export const AAADescription = ({ title, description }) => {
  return (
    <div className="c-aaa-description">
      <h1>{title && title}</h1>
      <p class="description">{description && description}</p>
    </div>
  );
};
