import "./3dHeader-style.scss";
import { Amazon3drendering_data } from "../../../../data";
import { IFrameContainer } from "../../../Global/ScheduleACall/ScheduleACall";
import { useToggle } from "../../../../hooks/useToggle/useToggle";

export const Amazon3DRenderingHeader = () => {
  const { title, tagline, services_content, get_a_quote, schedule_call } =
    Amazon3drendering_data.amazon_3d_header;

  const [state, toggle] = useToggle();

  return (
    <section className="c_amazon_d_rendering">
      <div className="parent_header_section">
        <h1 className="title">{title}</h1>

        <div className="a_content_listing">
          <ul className="photograogy_list">
            {services_content.map((data) => {
              return (
                <li>
                  {" "}
                  {data.icon} <span> {data.description} </span>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="tagline_a_content">
          <p>{tagline}</p>
        </div>

        <div className="content_header_btn">
          <a href="/contact-us" id="get_a_quote">
            {get_a_quote}
          </a>
          <a onClick={toggle} id="schedule">
            {schedule_call}
          </a>
        </div>
        {state && <IFrameContainer state={state} toggle={toggle} />}
      </div>
    </section>
  );
};
