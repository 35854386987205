import {
  AddImageSection,
  FixedBackground,
  HowItWorks1,
  PPCFaqs,
  PPCHeader,
  PPCService,
  ScheduleACall,
  SliderCarousel,
} from "../components";
import fixedBgImage from "../static/ppc-bg.jpg";
import PPCImage from "../static/ppcImage.png";

import { ppcManagement } from "../data";

const AmazonPPCManagement = () => {
  const {
    data: { header, includes_service, how_it_works, faq },
  } = ppcManagement;

  return (
    <div className="p-ppc-management">
      <PPCHeader headerData={header} />
      <PPCService servicesData={includes_service} />
      <HowItWorks1
        title={how_it_works.title}
        description={how_it_works.description}
        steps={how_it_works.steps}
      />

      <FixedBackground
        data={{
          image: fixedBgImage,
          content: "Reach Your ROI Goals with Biz Spartan!",
        }}
      />
      <AddImageSection imagesSrc={PPCImage} />
      <SliderCarousel />
      <PPCFaqs faqData={faq.faq_questions} />
      <ScheduleACall />
    </div>
  );
};

export default AmazonPPCManagement;
