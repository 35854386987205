import { Accordion } from "../../../Global/Accordion/Accordion";
import "./ALOWhatServiceCanDo-styles.scss";
import RightImage from "../../../../static/sketch_listing.webp";

export const ALOWhatServiceCanDo = () => {
  return (
    <div className="c-ALO-what-service-can-do">
      <div className="ALO-what-service-can-do-left">
        <h1 className="heading">
          Fuel Your Conversion Rate with Our Amazon Listing Optimization
          Service!
        </h1>
        <p className="description">
          Listing optimization gives your product a fighting chance in the
          fierce eCommerce competition. From search results to impressions to
          conversions, listing optimization offers numerous benefits to sellers.
          At Biz Spartan, we have experienced Amazon experts who understand
          Amazon’s ranking algorithm and can use this expertise to help your
          brand’s products get the eyes they deserve. Partner with Biz Spartan
          and work with top-tier sales copywriters and SEO experts.
        </p>
        <div className="faq-accordion">
          <h3>Order Our Amazon Listing Optimization Service & Get:</h3>
          <Accordion
            isShowTitle={false}
            accordionData={[
              {
                label: "Higher Rankings",
                content:
                  "Get our listing optimization service and rank your products on the first page of Amazon search queries. ",
              },
              {
                label: "Increased Conversions",
                content:
                  "You raise your conversion rates with increased traffic and impressions, making it a profitable decision. ",
              },
              {
                label: "Enhanced Brand Equity",
                content:
                  "Customers’ trust will increase when your products are optimized, enhancing your brand equity. ",
              },
            ]}
          />
        </div>
      </div>
      <div className="ALO-what-service-can-do-right">
        <div className="image">
          <img src={RightImage} className="right-image" />
        </div>
      </div>
    </div>
  );
};
