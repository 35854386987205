import { BsCheckLg, BsChatRightText } from "react-icons/bs";
import { SiGooglemeet } from "react-icons/si";
import { TbSettingsAutomation, TbNotes, TbChecklist } from "react-icons/tb";
import photographyImg from "../../static/photography-banner.webp";

export const productVideography = {
  name: "Product Videography",
  data: {
    header: {
      title: "Amazon Product Videography Service",
      tagline:
        "Showcase your products in motion graphics and increase conversions. At Biz Spartan, we use high-end camera equipment to get the best out of your products.",
      image: photographyImg,
      cta_btns: [
        {
          label: "Our Portfolio",
          link: "",
        },
        {
          label: "Our Packages",
          link: "",
        },
      ],
      services: [
        {
          icon: <BsCheckLg />,
          description: "In-house studios",
        },
        {
          icon: <BsCheckLg />,
          description:
            "Amazon product videos for listings, A+ content, and storefronts. ",
        },
        {
          icon: <BsCheckLg />,
          description: "Professional scriptwriters, directors, and producers.",
        },
        {
          icon: <BsCheckLg />,
          description: "Professional models.",
        },
        {
          icon: <BsCheckLg />,
          description: "Whiteboard animation product videos.",
        },
      ],
    },
    how_it_works: {
      name: "Amazon Product Videography",
      title: "Step-by-Step Videography Process",
      description:
        "This is how we create, direct, and edit your Amazon product videos at Biz Spartan.",
      steps: [
        {
          id: "01",
          title: "Discussion",
          description:
            "First, we discuss your videography needs, ensuring we are aware of your wants and expectations.",
          icon: <SiGooglemeet />,
        },
        {
          id: "02",
          title: "Planning",
          description:
            "Next, our scriptwriters, directors, and producers work to plan out the video through creative brainstorming and storyboarding.",
          icon: <TbNotes />,
        },
        {
          id: "03",
          title: "Production",
          description:
            "Then, we make the magic happen. Depending upon the requirement, we produce the video to your exact requirement.",
          icon: <TbSettingsAutomation />,
        },
        {
          id: "04",
          title: "Post-Production & Delivery",
          description:
            "Finally, we do some post-production touches, editing the video to perfection. After that, we’ll deliver the final source file to you. ",
          icon: <TbChecklist />,
        },
      ],
    },
    portfolio: {
      name: "Our Portfolio",
      title: "Our Amazon Videography Portfolio",
      description:
        "We cover everything from creative storyboarding to production and post-production touches. Over the years, Biz Spartan has worked with hundreds of brands and addressed their Amazon product video needs. Take a look at our hard work. ",

      portfolio_links: [
        "https://www.amzonestep.com/new/videos/Laundry-Basket-Draft_new.mp4",
        "https://www.amzonestep.com/new/videos/Prizko_new.mp4",
      ],
    },
    faq: {
      faq_questions: [
        {
          label: "What Type of Services Do You Guys Offer?",
          content:
            "At Biz Spartan, we offer everything Amazon. From listing optimization, keyword ranking, and EBC service to product photography/videography and account audit and management. We also provide Amazon PPC campaign services to give businesses an extra profit boost. ",
        },
        {
          label: "How Do I Make My Product Page Noticeable?",
          content:
            "This is a common question we get here at Biz Spartan. On Amazon, a good product page has all the right ingredients to make the page stand out, which includes high-res product photos, keyword-enriched content (title, listing, product descriptions, etc.), positive reviews, and quick customer support. At Biz Spartan, we help struggling sellers solve such complex factors, ensuring their page ranks on top.  ",
        },
        {
          label: "What Makes A Good Amazon Seller Account?",
          content:
            "A good Amazon seller account follows all the right rules to ensure Amazon guidelines and customer satisfaction. At Biz Spartan, our Amazon audit service will dive deep into your Amazon store and deliver a clear report underlying all the plausible discrepancies you need to work on. ",
        },
        {
          label: "Do You Provide Samples?",
          content:
            "For our product photography and videography services, we provide niche-based samples and past projects that we have worked on (with the seller’s consent) so that you may know about our work.  ",
        },
        {
          label: "Will You Use My Business Information?",
          content:
            "We take special precautions regarding the privacy and confidentiality of our Amazon sellers. Biz Spartan never shares, uses, or sells your information to any third party whatsoever. Any information you may feel comfortable sharing with us will be kept in the strictest confidence. ",
        },
      ],
    },
  },
};
