import {
  AAMFAQs,
  AAMHeader,
  AAMInventoryManagement,
  AddImageSection,
  ScheduleACall,
  SliderCarousel,
} from "../components";
import { accountManagement } from "../data/accountManagement/accountManagement";
import accountImg from "../static/accountImg.png";

const AmazonAccountManagement = () => {
  const {
    data: { header, faqs, inventory_management },
  } = accountManagement;
  return (
    <div className="amazon-account-management">
      <AAMHeader />
      <AAMInventoryManagement inventoryManagementData={inventory_management} />
      <AddImageSection imagesSrc={accountImg} />
      <SliderCarousel />
      <AAMFAQs faqData={faqs} />
      <ScheduleACall />
    </div>
  );
};

export default AmazonAccountManagement;
