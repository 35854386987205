import Routes from "./routes";
import { useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { Navbar, Footer } from "./components";

function App() {
  return (
    <div className="container">
      <TawkMessengerReact
        propertyId="62c4711cb0d10b6f3e7af1ff"
        widgetId="1g77ljmci"
      />
      <Navbar />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
