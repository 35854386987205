import {
  ALOHeader,
  ALOWhatServiceCanDo,
  ALOWhyBrandLoveUs,
  HowItWorks1,
  SliderCarousel,
  FixedBackground,
  ScheduleACall,
} from "../components";
import { BiPackage } from "react-icons/bi";
import BGPattern from "../static/pattern.svg";

const AmazonListingOptimization = () => {
  return (
    <div className="">
      <ALOHeader />
      <ALOWhyBrandLoveUs />
      <HowItWorks1
        title={"Step-By-Step Listing Optimization Process!"}
        steps={[
          {
            id: "01",
            title: "Consultation",
            description:
              "Talk to our experts and let them know of your listing optimization requirements. Tell us a bit about your brand and sales expectations. ",
            icon: <BiPackage />,
          },
          {
            id: "02",
            title: "Keyword Research",
            description:
              "Next, our SEO experts will conduct keyword research and find the top-performing keywords for your specific product range. ",
            icon: <BiPackage />,
          },
          {
            id: "03",
            title: "Planning",
            description:
              "Then, our sales copywriters and SEO specialists devise a robust strategy to ensure maximum optimization of your product listings. ",
            icon: <BiPackage />,
          },
          {
            id: "04",
            title: "Execution",
            description:
              "Finally, our experts get to work and deliver to your sales expectations. A report will be delivered to you after the conclusion of our service. ",
            icon: <BiPackage />,
          },
        ]}
      />
      <FixedBackground
        data={{
          image:
            "https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
          content: "Get Your Product List On The Top Of the Page on Amazon",
        }}
      />
      <ALOWhatServiceCanDo />
      <SliderCarousel />
      <ScheduleACall />
    </div>
  );
};

export default AmazonListingOptimization;
