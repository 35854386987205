import "./slider-style.scss";
import { testimonal_data } from "../../../data";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const SliderCarousel = () => {
  const { name, reviews } = testimonal_data;

  const [slideIndex, setSlickIndex] = useState(0);

  var settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    beforeChange: (current, next) => setSlickIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="c_slider">
      <div className="top">
        <div className="hr"></div>
        <h1 className="section-title">{name}</h1>
      </div>
      <Slider {...settings}>
        {reviews.map((data, index) => {
          return (
            <div
              className={index === slideIndex ? "slide slide-active" : "slide"}
              key={index}
            >
              <div className="testimonal_box">
                <a className="quoteImg" href="#">
                  {data.quoteIcon}
                </a>
                <div className="content">
                  <p className="testimonal_quote">{data.testimonal_comment}</p>
                </div>

                <div className="details">
                  <div className="ImgBox">
                    <a className="testimonal_image" href="#">
                      {data.testimonal_profile}
                    </a>
                    <h3 className="testi_name">
                      {data.testimonal_name}
                      {/* <br />
                      <span className="testimonal_positon">
                        {data.testimonal_position}
                      </span> */}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
