import {
  ProductPhotographyHeader,
  OneStepDifference,
  WhyBrandLoveUs,
  HowItWorks1,
  Photography_FAQs,
  SliderCarousel,
  Packages,
  AboutService,
  ProductPortfolio,
  ScheduleACall,
} from "../components";

import { product_photography_data } from "../data";

export const Our_Amazon_Product_Photography = () => {
  const { how_does_it_work_content, packages_data } = product_photography_data;
  return (
    <div className="our_photography_container">
      <ProductPhotographyHeader />
      <OneStepDifference />
      <ProductPortfolio />
      <AboutService />
      <HowItWorks1
        title={how_does_it_work_content.title}
        description={how_does_it_work_content.description}
        steps={how_does_it_work_content.steps}
      />
      <WhyBrandLoveUs />
      <Photography_FAQs />
      <SliderCarousel />
      <Packages
        title={packages_data.title}
        packages_name={packages_data.packages_name}
      />
      <ScheduleACall />
    </div>
  );
};
