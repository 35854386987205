import {
  AAADescription,
  AAAFAQs,
  AAAHeader,
  FixedBackground,
  HowItWorks1,
  ScheduleACall,
  SliderCarousel,
} from "../components";
import AAAFixedImage from "../static/AAAFixed-bg.jpg";
import { accountAudit } from "../data";

const AmazonAccountAudit = () => {
  const {
    data: { faqs, how_it_works, header, description },
  } = accountAudit;
  return (
    <div className="p-amazon-account-audit">
      <AAAHeader title={header.title} description={header.description} />
      <AAADescription
        title={description.title}
        description={description.description}
      />
      <FixedBackground
        data={{
          content: "Get Your Amazon Account Audited At Biz Spartan!",
          image: AAAFixedImage,
        }}
      />
      <HowItWorks1
        description={how_it_works.description}
        title={how_it_works.title}
        steps={how_it_works.steps}
      />
      <SliderCarousel />
      <AAAFAQs faqData={faqs} />
      <ScheduleACall />
    </div>
  );
};

export default AmazonAccountAudit;
