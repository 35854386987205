import "./apvHeader-styles.scss";

export const APVHeader = ({ headerData }) => {
  const { title, tagline, services, cta_btns, image } = headerData;
  console.log(services);
  return (
    <div className="c-apv-header">
      <div className="parent-header-videography">
        <div className="description-section">
          <h1 className="product-video-heading">{title}</h1>
          <h5 className="tagline">{tagline}</h5>
          <div>
            <ul className="videography-features-list">
              {services.map((data) => {
                return (
                  <li>
                    {" "}
                    {data.icon} <span> {data.description} </span>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="APV-header-btn">
            {!!cta_btns.length &&
              cta_btns.map((btn) => {
                return <a className="cta-btn">{btn.label}</a>;
              })}
          </div>
        </div>

        <div className="image_section">
          <video controls>
            <source
              src="https://www.amzonestep.com/new/videos/banner-video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};
