import { Accordion } from "../../../Global/Accordion/Accordion";
import "./aamFAQs-styles.scss";

export const AAMFAQs = ({ faqData }) => {
  const { faq_questions, title, description } = faqData;
  return (
    <div className="c-aam-faq">
      <div className="aam-faq-container">
        <h1>Frequently Ask Questions</h1>
        <div className="faq-section">
          <Accordion isShowTitle={false} accordionData={faq_questions} />
        </div>
      </div>
    </div>
  );
};
