import {
  MdLocationPin,
  MdOutlineMail,
  MdPhone,
  MdFacebook,
} from "react-icons/md";
import { FiClock } from "react-icons/fi";
import { BsTwitter, BsInstagram } from "react-icons/bs";

export const footer = {
  about_us: {
    text: "About Us",
    description:
      "Biz Spartan is an Amazon consultancy firm that works to serve Amazon sellers worldwide. Please read our terms and conditions before using our website.",
  },
  contact_details: {
    text: "Get in touch",
    contact_data: [
      {
        icon: <MdLocationPin />,
        name: "Street",
        description: "7901 4th St. N STE 10120, St. Petersburg, FL 33702",
      },
      {
        icon: <MdPhone />,
        name: "Phone",
        description: "+1 386 272 2722",
      },
      {
        icon: <MdOutlineMail />,
        name: "Email",
        description: "contact@bizspartan.com",
      },
      {
        icon: <FiClock />,
        name: "Timings",
        description: "Mon - Fri, 9AM - 10PM",
      },
    ],
  },
  useful_links: {
    text: "Useful Links",
    links: [
      {
        name: "Home",
        href: "/",
      },
      {
        name: "Amazon Product Photography",
        href: "/services/amazon-product-photography",
      },
      {
        name: "Amazon Product Videography",
        href: "/services/amazon-product-videography",
      },
      {
        name: "Amazon Listing Optimization",
        href: "/services/amazon-listing-optimization",
      },
      {
        name: "Amazon PPC Management",
        href: "/services/amazon-ppc-management",
      },
      {
        name: "Amazon A+ Content Service",
        href: "/services/ebc-service",
      },
      {
        name: "About Us",
        href: "/about-us",
      },
      {
        name: "Contact Us",
        href: "/contact-us",
      },
    ],
  },
  social_links: {
    text: "Social Links",
    description: "Let’s connect with each other",
    links: [
      {
        name: "facebook",
        href: "https://www.facebook.com/bizspartan2022",
        icon: <MdFacebook />,
      },
      {
        name: "twitter",
        href: "https://www.twitter.com/bizspartan",
        icon: <BsTwitter />,
      },
      {
        name: "instragram",
        href: "https://www.instagram.com/bizspartan",
        icon: <BsInstagram />,
      },
    ],
  },
};
