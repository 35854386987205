import {
  MdLocationPin,
  MdOutlineMail,
  MdPhone,
  MdFacebook,
} from "react-icons/md";
import { BsTwitter, BsInstagram, BsYoutube } from "react-icons/bs";

export const contact_form_data = {
  name: "Contact Info",
  contact_form: {
    more_details: [
      {
        icon: <MdPhone />,
        name: "Phone",
        description: "021-2233322332",
        href: "#",
      },
      {
        icon: <MdOutlineMail />,
        name: "email",
        description: "contactjohn@gmail.com",
        href: "#",
      },
      {
        icon: <MdLocationPin />,
        name: "Location",
        description: "L-1999 sector 11A North Karachi, Karachi Pakistan",
        href: "#",
      },
    ],
  },
  social_links: {
    links: [
      {
        name: "facebook",
        href: "#",
        icon: <MdFacebook />,
      },
      {
        name: "twitter",
        href: "#",
        icon: <BsTwitter />,
      },
      {
        name: "instragram",
        href: "#",
        icon: <BsInstagram />,
      },
    ],
  },
  fields_data: {
    fields: [
      {
        type: "text",
        placeholder: "Enter Your Name",
        name: "name",
        field_space: "short",
      },
      {
        type: "email",
        placeholder: "Enter Your Email",
        name: "email",
        field_space: "short",
      },
      {
        type: "text",
        placeholder: "Enter Your ASIN",
        name: "ASIN",
        pattern: "[a-zA-Z0-9]+",
        field_space: "short",
      },
      {
        type: "text",
        placeholder: "Enter Your Message",
        name: "message",
        pattern: "[a-zA-Z0-9]+",
        field_space: "long",
      },
    ],
  },
  button: {
    name: "SUBMIT",
    type: "submit",
  },
  text_area: {
    type: "text",
    placeholder: "Enter Your Message",
    name: "message",
    field_space: "long",
  },
};
