import "./footer-styles.scss";
import { footer } from "../../../data";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { about_us, contact_details, useful_links, social_links } = footer;
  return (
    <>
      <section className="c-footer-section">
        <div className="container">
          <div className="parent-box-footer">
            <div className="grid-items">
              <div className="about-us-section">
                <h2>{about_us.text}</h2>
                <hr />
                <p>{about_us.description}</p>
              </div>
            </div>

            <div className="grid-items">
              <div className="get_in_section">
                <h2>{contact_details.text}</h2>
                <hr />
                <div>
                  <ul>
                    {contact_details.contact_data.map((data) => {
                      return (
                        <li>
                          <a href="#">{data.icon}</a>
                          {data.description}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="grid-items">
              <div className="useful-section">
                <h2>{useful_links.text}</h2>
                <hr />
                <div>
                  <ul>
                    {useful_links.links.map((link) => {
                      return (
                        <li>
                          <Link to={link.href}>{link.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="grid-items">
              <div className="about-us-section">
                <h2>{social_links.text}</h2>
                <hr />
                <p>{social_links.description}</p>
                <div>
                  <ul>
                    {social_links.links.map((data) => {
                      return (
                        <li>
                          <a target="_blank" href={data.href}>{data.icon}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
