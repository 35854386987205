import "./One_Step_Difference.scss";

import { product_photography_data } from "../../../../data";

export const OneStepDifference = () => {
  const { title, description, onestepImg } =
    product_photography_data.one_step_difference_section;
  return (
    <section className="c_one_step_photography_section">
      <div className="parent_one_step_photography">
        <div className="one_step_image_section">
          <img className="img" src={onestepImg} />
        </div>
        <div className="one_step_description_section">
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
        </div>
      </div>
    </section>
  );
};
