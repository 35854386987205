import { ContactUs } from "../components";

const Contact = () => {
  return (
    <div className="contact-container">
      <ContactUs />
    </div>
  );
};

export default Contact;
