import {
  APVHeader,
  APVPortfolio,
  HowItWorks1,
  ScheduleACall,
  SliderCarousel,
  APVFaqs,
} from "../components";
import { productVideography } from "../data";

const AmazonProductVideography = () => {
  const {
    data: { header, how_it_works, portfolio, faq },
  } = productVideography;

  return (
    <div className="">
      <APVHeader headerData={header} />
      <HowItWorks1
        title={how_it_works?.title}
        description={how_it_works.description}
        steps={how_it_works.steps}
      />
      <APVPortfolio
        title={portfolio.title}
        description={portfolio.description}
        portfolioLinks={portfolio.portfolio_links}
      />
      <SliderCarousel />
      <APVFaqs faqData={faq.faq_questions} />
      <ScheduleACall />
    </div>
  );
};

export default AmazonProductVideography;
