import "./product-header-style.scss";

import { product_photography_data } from "../../../../data";

export const ProductPhotographyHeader = () => {
  const { title, tagline, services, ourPortfolio, ourpackages, image } =
    product_photography_data.product_photography_header;
  return (
    <div className="c_product_photo_header">
      <div className="parent_header_photography">
        <div className="description_section">
          <h1 className="product_photo_heading">{title}</h1>
          <h5 className="tagline">{tagline}</h5>
          <div>
            <ul className="photograogy_list">
              {services.map((data) => {
                return (
                  <li>
                    {" "}
                    {data.icon} <span> {data.description} </span>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="photo_header_btn">
            <a id="portfolio">{ourPortfolio}</a>
            <a id="ourPackages">{ourpackages}</a>
          </div>
        </div>

        <div className="image_section">
          <img className="header_photo_img" src={image} />
        </div>
      </div>
    </div>
  );
};
