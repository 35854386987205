import "./apvPortfolio-styles.scss";

export const APVPortfolio = ({
  title = "",
  portfolioLinks = [],
  description = "",
}) => {
  return (
    <div className="c-apv-portfolio">
      <h1 className="apv-heading">{title}</h1>
      <p className="apv-description">{description}</p>
      <div className="apv-portfolio-container">
        {!!portfolioLinks.length &&
          portfolioLinks.map((link) => {
            return (
              <div className="video">
                <video controls>
                  <source src={link} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          })}
      </div>
    </div>
  );
};
