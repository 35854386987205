import "./bring-products-style.scss";

import { Amazon3drendering_data } from "../../../../data";

export const BringProducts = () => {
  const { title, description, bringProductsImg } =
    Amazon3drendering_data.one_step_difference_section_3d;
  return (
    <section className="c_bring_products_3d_section">
      <div className="parent_one_step_photography">
        <div className="one_step_description_section">
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
        </div>

        <div className="one_step_image_section">
          <img className="img" src={bringProductsImg} />
        </div>
      </div>
    </section>
  );
};
