import { SiGooglemeet } from "react-icons/si";
import { TbNotes, TbSettingsAutomation, TbChecklist } from "react-icons/tb";

export const accountAudit = {
  name: "Amazon Account Audit",
  data: {
    header: {
      title: "Get Your Amazon Account Analyzed & Evaluated",
      description:
        "Find out the strengths and weaknesses of your Amazon seller account at Biz Spartan.",
    },
    description: {
      title: "Our Amazon Account Audit Service",
      description:
        "Are you an Amazon seller who is wondering why isn’t your products getting noticed? You are not alone. There might be numerous flaws in your Amazon strategy. At Biz Spartan, we provide experienced and professional Amazon auditors who dig deep into your Amazon account, diagnosing the weaknesses of your Amazon product page.  ",
    },
    faqs: {
      title: "Frequently Asked Questions",
      description:
        "here are the answers of the most common questions we asked from our valued customers.",
      faq_questions: [
        {
          label: "What Type of Services Do You Guys Offer?",
          content:
            "At Biz Spartan, we offer everything Amazon. From listing optimization, keyword ranking, and EBC service to product photography/videography and account audit and management. We also provide Amazon PPC campaign services to give businesses an extra profit boost. ",
        },
        {
          label: "How Do I Make My Product Page Noticeable?",
          content:
            "This is a common question we get here at Biz Spartan. On Amazon, a good product page has all the right ingredients to make the page stand out, which includes high-res product photos, keyword-enriched content (title, listing, product descriptions, etc.), positive reviews, and quick customer support. At Biz Spartan, we help struggling sellers solve such complex factors, ensuring their page ranks on top.  ",
        },
        {
          label: "What Makes A Good Amazon Seller Account?",
          content:
            "A good Amazon seller account follows all the right rules to ensure Amazon guidelines and customer satisfaction. At Biz Spartan, our Amazon audit service will dive deep into your Amazon store and deliver a clear report underlying all the plausible discrepancies you need to work on. ",
        },
        {
          label: "Do You Provide Samples?",
          content:
            "For our product photography and videography services, we provide niche-based samples and past projects that we have worked on (with the seller’s consent) so that you may know about our work.  ",
        },
        {
          label: "Will You Use My Business Information?",
          content:
            "We take special precautions regarding the privacy and confidentiality of our Amazon sellers. Biz Spartan never shares, uses, or sells your information to any third party whatsoever. Any information you may feel comfortable sharing with us will be kept in the strictest confidence. ",
        },
      ],
    },
    how_it_works: {
      name: "Amazon Product Videography",
      title: "Step-By-Step Auditing Process",
      description:
        "We keep our process transparent. This is how we audit your Amazon account.",
      steps: [
        {
          id: "01",
          title: "You Contact Us",
          description:
            "You can contact us through our website’s contact form or call us directly on our number, both of which are provided on the Biz Spartan website. ",
          icon: <SiGooglemeet />,
        },
        {
          id: "02",
          title: "We Understand Your Needs",
          description:
            "Next, we ask you to tell us about your business and the problem you are facing. Our customer representatives note down each and everything. ",
          icon: <TbNotes />,
        },
        {
          id: "03",
          title: "We Plan & Execute",
          description:
            "Then, our Amazon audit specialists chart out a comprehensive plan for your Amazon account auditing and execute it to find out the root problems.  ",
          icon: <TbSettingsAutomation />,
        },
        {
          id: "04",
          title: "We Execute",
          description:
            "Finally, we deliver an easy-to-understand report containing all the relevant information about your Amazon account’s strengths and weaknesses. ",
          icon: <TbChecklist />,
        },
      ],
    },
  },
};
