import { Accordion } from "../../../Global/Accordion/Accordion";
import "./aaaFAQs-styles.scss";

export const AAAFAQs = ({ faqData }) => {
  const { faq_questions, title, description } = faqData;
  return (
    <div className="c-aaa-faq">
      <div className="aaa-faq-container">
        <h1>{title && title}</h1>
        <div className="faq-section">
          <Accordion isShowTitle={false} accordionData={faq_questions} />
        </div>
      </div>
    </div>
  );
};
