export const accountManagement = {
  name: "Amazon Account Management",
  data: {
    header: {
      title: "All-In-One Amazon Account Management Services!",
      description:
        "You focus on the bigger picture; let our virtual assistants deal with the day-to-day operations of your Amazon account. ",
    },
    faqs: {
      title: "Frequently Asked Questions",
      description:
        "here are the answers of the most common questions we asked from our valued customers.",
      faq_questions: [
        {
          label: "What Type of Services Do You Guys Offer?",
          content:
            "At Biz Spartan, we offer everything Amazon. From listing optimization, keyword ranking, and EBC service to product photography/videography and account audit and management. We also provide Amazon PPC campaign services to give businesses an extra profit boost. ",
        },
        {
          label: "How Do I Make My Product Page Noticeable?",
          content:
            "This is a common question we get here at Biz Spartan. On Amazon, a good product page has all the right ingredients to make the page stand out, which includes high-res product photos, keyword-enriched content (title, listing, product descriptions, etc.), positive reviews, and quick customer support. At Biz Spartan, we help struggling sellers solve such complex factors, ensuring their page ranks on top.  ",
        },
        {
          label: "What Makes A Good Amazon Seller Account?",
          content:
            "A good Amazon seller account follows all the right rules to ensure Amazon guidelines and customer satisfaction. At Biz Spartan, our Amazon audit service will dive deep into your Amazon store and deliver a clear report underlying all the plausible discrepancies you need to work on. ",
        },
        {
          label: "Do You Provide Samples?",
          content:
            "For our product photography and videography services, we provide niche-based samples and past projects that we have worked on (with the seller’s consent) so that you may know about our work.  ",
        },
        {
          label: "Will You Use My Business Information?",
          content:
            "We take special precautions regarding the privacy and confidentiality of our Amazon sellers. Biz Spartan never shares, uses, or sells your information to any third party whatsoever. Any information you may feel comfortable sharing with us will be kept in the strictest confidence. ",
        },
      ],
    },
    inventory_management: {
      title: "Inventory Management",
      description:
        "We have a dedicated team of Virtual Assistants who will take up most of your mundane tasks while you focus on other aspects of your business.",
      management_steps: [
        {
          title: "Maximize Your Brand Equity With Biz Spartan!",
          description:
            "At Biz Spartan, we have dedicated virtual assistants who cater to clients’ Amazon seller central accounts. Our Amazon account management service covers:",
          sub_steps: [
            {
              for: "Suppliers",
              for_list: [
                "Product ID & Prices.",
                "Order Date & Shipment received.",
              ],
            },
            {
              for: "Customers",
              for_list: [
                "Customer Id & Addresses.",
                "Payment Methods.",
                "Order Dates and Delivery.",
              ],
            },
          ],
        },
        {
          title: "Managing Seller Central Account",
          description:
            "At Biz Spartan, we handle all the issues pertaining to your Amazon seller central account. Our virtual assistants resolve issues for you and present you with the report.",
          sub_steps: [
            {
              for: "All",
              for_list: [
                "Return & Refund Management Sheet.",
                "Return & Refund Requests.",
              ],
            },
          ],
        },
        {
          title: "A+ Content",
          description:
            "Our creative Amazon copywriters enhance your brand content by highlighting the unique selling point of your products with captivating imagery to gain customer trust and conversion. ",
          sub_steps: [
            {
              for: "Suppliers",
              for_list: [
                "Complete Return record against the Order number.",
                "Returned date & created by.",
                "Refunded Amount.",
                "Return and refund status.",
              ],
            },
          ],
        },
        {
          title: "Search Engine Optimization (SEO)",
          description:
            "Ranking is important when it comes to Amazon products. Our experienced Amazon SEO experts conduct deep research and choose the most profitable keywords to boost your products’ organic rankings. ",
          sub_steps: [
            {
              for: "Suppliers",
              for_list: [
                "Complete Return record against the Order number.",
                "Returned date & created by.",
                "Refunded Amount.",
                "Return and refund status.",
              ],
            },
          ],
        },
        {
          title: "Listings Optimization",
          description:
            "We also make sure that your product listing is optimized to the T. Our SEO experts devise a potent optimization strategy to ensure your conversion rate goals. ",
          sub_steps: [
            {
              for: "Suppliers",
              for_list: [
                "Complete Return record against the Order number.",
                "Returned date & created by.",
                "Refunded Amount.",
                "Return and refund status.",
              ],
            },
          ],
        },
        {
          title: "Brand Registry & Management",
          description:
            "Registering your brand on Amazon Brand Registry (ABR) is a tall task. Our Amazon experts will manage all the processes and management operations pertaining to the brand registry with Amazon. ",
          sub_steps: [
            {
              for: "Suppliers",
              for_list: [
                "Complete Return record against the Order number.",
                "Returned date & created by.",
                "Refunded Amount.",
                "Return and refund status.",
              ],
            },
          ],
        },
      ],
    },
  },
};
