import "./howItWorks1-styles.scss";
import { BiPackage } from "react-icons/bi";

/*

    @props title, description and steps
    title: title of the section e.g How it works?
    description: description of the section.
    steps: steps is the card data give an array of objects with card details.

*/

export const HowItWorks1 = ({ title, description, steps }) => {
  return (
    <div className="c-how-it-works-1">
      <div className="header">
        <div className="left">
          <div className="hr"></div>
          <h2 id="heading">{title}</h2>
        </div>
        <p className="description">{description}</p>
      </div>
      <div className="body">
        {steps.length > 0 &&
          steps.map((step) => {
            return <Card cardData={step} />;
          })}
      </div>
    </div>
  );
};

/*

    @props cardData
    
    cardData will contains the following details:
    id: id the of thc card
    title: title of the card/step
    description: description of the card
    icon: Icon that show how it works?

*/

const Card = ({ cardData }) => {
  return (
    <div className="how-it-work-card">
      <div className="wrapper">
        <div className="overlay">
          <h1 className="card-number-id">{cardData?.id}</h1>
        </div>
        <div className="content">
          <div className="icon">{cardData.icon}</div>
          <h2 className="card-heading">{cardData?.title}</h2>
          <p>{cardData?.description}</p>
        </div>
      </div>
    </div>
  );
};
