import { BsPersonCircle, BsChatRightQuote } from "react-icons/bs";
import testimonalImg from "../../static/slider.jpeg";

export const testimonal_data = {
  name: "Testimonials",
  reviews: [
    {
      testimonal_name: "ZMAK LTD",
      testimonal_position: "Software Engineer",
      testimonal_profile: <BsPersonCircle />,
      testimonal_comment:
        "Finding a good person to manage your amazon business is always hard nowadays, I started working with biz spartan and I am pretty satisfied with their work. They are always dedicated to their tasks. Communication is very good, I would definitely launch my other stores with them as well.",
      icon: testimonalImg,
      quoteIcon: <BsChatRightQuote />,
    },
    {
      testimonal_name: "PVR Enterprises",
      testimonal_position: "Software Engineer",
      testimonal_profile: <BsPersonCircle />,
      testimonal_comment:
        "Biz Spartan has been my only account managers. Their creative team is very creative and awesome. I would definitely recommend them.",
      icon: testimonalImg,
      quoteIcon: <BsChatRightQuote />,
    },
    {
      testimonal_name: "HSA Enterprise",
      testimonal_position: "Software Engineer",
      testimonal_profile: <BsPersonCircle />,
      testimonal_comment:
        "Biz Spartan is the reason why we are in the market. Our business was declining progressively and so came in BizSpartan to not just solve our problems but went ahead past our expectations by a great margin!",
      icon: testimonalImg,
      quoteIcon: <BsChatRightQuote />,
    },
    {
      testimonal_name: "KS Shears LTD",
      testimonal_position: "Software Engineer",
      testimonal_profile: <BsPersonCircle />,
      testimonal_comment:
        "It was always a pleasure to work with bit spartan. They are very professional, punctual and committed to their work. I started from scratch and they helped me to get some good numbers on and off amazon as well. I would highly recommend them.",
      icon: testimonalImg,
      quoteIcon: <BsChatRightQuote />,
    },
    // {
    //   testimonal_name: "John",
    //   testimonal_position: "Software Engineer",
    //   testimonal_profile: <BsPersonCircle />,
    //   testimonal_comment:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   icon: testimonalImg,
    //   quoteIcon: <BsChatRightQuote />,
    // },
    // {
    //   testimonal_name: "John",
    //   testimonal_position: "Software Engineer",
    //   testimonal_profile: <BsPersonCircle />,
    //   testimonal_comment:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   icon: testimonalImg,
    //   quoteIcon: <BsChatRightQuote />,
    // },
    // {
    //   testimonal_name: "John",
    //   testimonal_position: "Software Engineer",
    //   testimonal_profile: <BsPersonCircle />,
    //   testimonal_comment:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   icon: testimonalImg,
    //   quoteIcon: <BsChatRightQuote />,
    // },
    // {
    //   testimonal_name: "John",
    //   testimonal_position: "Software Engineer",
    //   testimonal_profile: <BsPersonCircle />,
    //   testimonal_comment:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   icon: testimonalImg,
    //   quoteIcon: <BsChatRightQuote />,
    // },
    // {
    //   testimonal_name: "John",
    //   testimonal_position: "Software Engineer",
    //   testimonal_profile: <BsPersonCircle />,
    //   testimonal_comment:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   icon: testimonalImg,
    //   quoteIcon: <BsChatRightQuote />,
    // },
  ],
};
