import "./our-services-styles.scss";
import { our_services_data } from "../../../data";
import { ServicesCard } from "./ServicesCard/ServicesCard";

export const OurServices = () => {
  const { content, name, services } = our_services_data;
  return (
    <div className="c-our-services">
      <div className="head">
        <div className="left">
          <div className="hr"></div>
          <h2 id="our-services-heading">{name}</h2>
        </div>
        <div className="right">
          <p className="our-services-content">{content}</p>
        </div>
      </div>
      <div className="body">
        <ServicesCard services={services} />
      </div>
    </div>
  );
};
