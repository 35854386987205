import "./faq3d-style.scss";
import { Accordion } from "../../../Global/Accordion/Accordion";

import { Amazon3drendering_data } from "../../../../data";

export const Faqs3D = () => {
  const { FAQs_section } = Amazon3drendering_data;
  return (
    <section className="faqs_3d">
      <Accordion accordionData={FAQs_section} />
    </section>
  );
};
