import { BsCheckLg } from "react-icons/bs";
import photographyImg from "../../static/photography-banner.webp";
import cameraImg from "../../static/photography-camera-banner2.webp";
import onestep from "../../static/oneStep.jpg";
import { TbFriends, TbDeviceDesktop, TbHome, TbPackage } from "react-icons/tb";
import whiteback1 from "../../static/whiteback1.jpg";
import whiteback2 from "../../static/whiteback2.jpg";
import whiteback3 from "../../static/whiteback3.png";
import whiteback4 from "../../static/whiteback4.jpeg";
import whiteback5 from "../../static/whiteback5.jpg";
import whiteback6 from "../../static/whiteback6.jpg";
import whiteback7 from "../../static/whiteback7.jpg";
import whiteback8 from "../../static/whiteback8.jpg";

import life1 from "../../static/life1.jpg";
import life2 from "../../static/life2.jpg";
import life3 from "../../static/life3.jpg";
import life4 from "../../static/life4.jpg";
import life5 from "../../static/life5.jpg";
import life6 from "../../static/life6.jpg";
import life7 from "../../static/life7.jpg";
import life8 from "../../static/life8.jpg";

import info1 from "../../static/info1.jpg";
import info2 from "../../static/info2.jpg";
import info3 from "../../static/info3.jpeg";
import info4 from "../../static/info4.jpg";
import info5 from "../../static/info5.jpg";
import info6 from "../../static/info6.jpg";
import info7 from "../../static/info7.jpg";
import info8 from "../../static/info8.jpg";

export const product_photography_data = {
  product_photography_header: {
    title: "Amazon Product Photography Service",
    tagline:
      "Lights, camera, and shoot! At Biz Spartan, our professional photographers bring your products to life.",
    ourPortfolio: "OUR PORTFOLIO",
    ourpackages: "OUR PACKAGES",
    image: photographyImg,
    services: [
      {
        icon: <BsCheckLg />,
        description: "Professional Amazon Product Photographers",
      },
      {
        icon: <BsCheckLg />,
        description: "Cater to 100+ Product Niches",
      },
      {
        icon: <BsCheckLg />,
        description: "Lifestyle Images with Professional Models",
      },
      {
        icon: <BsCheckLg />,
        description: "Eye-Grabbing Amazon Infographics",
      },
      {
        icon: <BsCheckLg />,
        description: "White Background Amazon Photography",
      },
    ],
  },
  one_step_difference_section: {
    title: "Enhance Your Amazon Conversions with Stellar Product Photography!",
    description:
      "A picture speaks a thousand words. This is particularly true in eCommerce. Sellers are up in arms in the warzone that is Amazon, battling the competition to emerge at the top. If you are an Amazon seller, chances are, you are already in a saturated niche. How can you stand apart from the competition? The answer lies in our Amazon product photography service. Our professional Amazon photographers bring your products to life through careful composition, proper lighting, and fine attention to detail.",
    onestepImg: onestep,
  },
  why_brand_love_us: {
    title: "Why Sellers Prefer Us!",
    description:
      "Our Amazon product photographers understand consumerism and know customers’ expectations.",
    whyloveusbenefits: [
      {
        icon: <BsCheckLg />,
        benefits: "4+ Years of eCommerce Experience",
      },
      {
        icon: <BsCheckLg />,
        benefits: "In-house Studios & Professional Models",
      },
      {
        icon: <BsCheckLg />,
        benefits: "All-in-One Photography Service",
      },
      {
        icon: <BsCheckLg />,
        benefits: "Over 30,000+ Amazon Images Created",
      },
      {
        icon: <BsCheckLg />,
        benefits: "Professional High-Tech Camera Equipment",
      },
    ],
  },
  how_does_it_work_content: {
    name: "HowDoesItWork",
    title: "Step-by-Step Order Process!",
    description: "",
    steps: [
      {
        id: 1,
        name: "WeListen",
        title: "We Listen",
        icon: <TbFriends />,
        description:
          "Biz Spartan listens to the requirements of clients and understands their business needs.",
      },
      {
        id: 2,
        name: "WeStrategize",
        title: "We Strategize",
        icon: <TbDeviceDesktop />,
        description:
          "We devise practical photography strategies based on the requirement and type of business.",
      },
      {
        id: 3,
        name: "WeWork",
        title: "We Work",
        icon: <TbHome />,
        description:
          "As soon as we finalize a potent strategy for your brand, our photographers work their magic.",
      },
      {
        id: 4,
        name: "WeDeliver",
        title: "We Deliver",
        icon: <TbPackage />,
        description:
          "Lastly, we conclude our service by delivering you high-res product images, along with source files.",
      },
    ],
  },
  packages_data: {
    title: "Our Amazon Photography Packages",
    packages_name: [
      {
        id: 1,
        name: "Standardpack",
        title: "STANDARD PACK",
        getQuote: "GET A QUOTE",
        packages_list: [
          {
            icon: <BsCheckLg />,
            description:
              "7 Total Images (White Background Images, Infographics, Lifestyle Images)",
          },
          {
            icon: <BsCheckLg />,
            description: "Licensed Stock Photos",
          },
          {
            icon: <BsCheckLg />,
            description: "Customized Storyboard",
          },
          {
            icon: <BsCheckLg />,
            description: "Two Revisions",
          },
          {
            icon: <BsCheckLg />,
            description: "Dedicated Account Manager",
          },
          {
            icon: <BsCheckLg />,
            description: "Full Image Rights and Ownership",
          },
        ],
      },
      {
        id: 2,
        name: "RealLifePhotography",
        title: "REAL LIFE PHOTOGRAPHY",
        getQuote: "GET A QUOTE",
        packages_list: [
          {
            icon: <BsCheckLg />,
            description:
              "7 Total Images (White Background Images, Infographics, Lifestyle Images)",
          },
          {
            icon: <BsCheckLg />,
            description: "In House Lifestyle Studio",
          },
          {
            icon: <BsCheckLg />,
            description: "Professional Model",
          },
          {
            icon: <BsCheckLg />,
            description: "Customized Storyboard",
          },
          {
            icon: <BsCheckLg />,
            description: "Two Revisions",
          },
          {
            icon: <BsCheckLg />,
            description: "Dedicated Account Manager",
          },
          {
            icon: <BsCheckLg />,
            description: "Full Image Rights and Ownership",
          },
        ],
      },
      {
        id: 3,
        name: "SuperPack",
        title: "SUPER PACK",
        getQuote: "GET A QUOTE",
        packages_list: [
          {
            icon: <BsCheckLg />,
            description:
              "7 Total Images (White Background Images, Infographics, Lifestyle Images)",
          },
          {
            icon: <BsCheckLg />,
            description: "Licensed Stock Photos",
          },
          {
            icon: <BsCheckLg />,
            description: "Customized Storyboard",
          },
          {
            icon: <BsCheckLg />,
            description: "Two Revisions",
          },
          {
            icon: <BsCheckLg />,
            description: "Dedicated Account Manager",
          },
          {
            icon: <BsCheckLg />,
            description: "Full Image Rights and Ownership",
          },
        ],
      },
      {
        id: 4,
        name: "ConversionBooster",
        title: "CONVERSION BOOSTER",
        getQuote: "GET A QUOTE",
        packages_list: [
          {
            icon: <BsCheckLg />,
            description:
              "7 Total Images (White Background Images, Infographics, Lifestyle Images)",
          },
          {
            icon: <BsCheckLg />,
            description: "Licensed Stock Photos",
          },
          {
            icon: <BsCheckLg />,
            description: "Customized Storyboard",
          },
          {
            icon: <BsCheckLg />,
            description: "Two Revisions",
          },
          {
            icon: <BsCheckLg />,
            description: "Dedicated Account Manager",
          },
          {
            icon: <BsCheckLg />,
            description: "Full Image Rights and Ownership",
          },
        ],
      },
    ],
  },
  FAQs_section: [
    {
      label: "What is your services?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "Are you people working on Amazon?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "What is the difference between dropshipping and PL ?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "Can you please explain us FBM?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "Give me some demo of your work.",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
    {
      label: "In which business you are working on?",
      content:
        "We deliver our best work in all our services. Our packages is good for all of our users and client. We did and we will.",
    },
  ],
  about_service_data: {
    title: "Tell Your Brand Story with Our Amazon Product Photography!",
    name: "About Our Photography",
    cameraImage: cameraImg,
    about_description: [
      {
        description:
          "At Biz Spartan, we help Amazon sellers to establish their brand and compete with the big names through cost-effective yet high-quality product photography service. What separates us from our peers is that we pay attention to detail and actually listen to our client’s requirements.",
      },
      {
        description:
          "We harbor industry professionals with credible experience in eCommerce photography. Our state-of-the-art in-house studios are where the magic happens. Our Amazon product photographers shoot lifestyle, infographics, and white background product images, depending on your requirement.",
      },
    ],
  },
  product_portfolio: {
    title: "Our Amazon Product Photography",
    description:
      "We are not just words; we let our work speak for itself. Over the years, Biz Spartan has helped numerous small and big Amazon brands shine above the competition through eye-grabbing product imagery. Take a look at the work Biz Spartan has achieved over the years.",
    white_background: {
      name: "White Background",
      first_images: [
        {
          images: whiteback1,
        },
        {
          images: whiteback2,
        },
        {
          images: whiteback3,
        },
        {
          images: whiteback4,
        },
        {
          images: whiteback5,
        },
        {
          images: whiteback6,
        },
        {
          images: whiteback7,
        },
        {
          images: whiteback8,
        },
      ],
    },
    life_style: {
      name: "Life Style",
      first_images: [
        {
          images: life1,
        },
        {
          images: life2,
        },
        {
          images: life3,
        },
        {
          images: life4,
        },
        {
          images: life5,
        },
        {
          images: life6,
        },
        {
          images: life7,
        },
        {
          images: life8,
        },
      ],
    },
    infp_graphics: {
      name: "Info Graphics",
      first_images: [
        {
          images: info1,
        },
        {
          images: info2,
        },
        {
          images: info3,
        },
        {
          images: info4,
        },
        {
          images: info5,
        },
        {
          images: info6,
        },
        {
          images: info7,
        },
        {
          images: info8,
        },
      ],
    },
  },
};
