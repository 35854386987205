import "./projectsDoneCounter-styles.scss";
import { projectsDoneCounter } from "../../../data";

export const ProjectsDoneCounter = () => {
  const { title, project_categories } = projectsDoneCounter;
  return (
    <div className="c-projects-done-counter">
      <div className="blob-overlay">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#ff98001a"
            d="M24.2,-32.9C33.9,-20.8,45.9,-15.5,50.7,-6.3C55.4,2.9,52.9,16,44.7,21.8C36.6,27.7,22.8,26.3,9.5,35C-3.8,43.7,-16.7,62.5,-29.8,65C-42.9,67.6,-56.3,53.9,-64.5,38C-72.8,22.1,-76,4.1,-73.5,-13.5C-71,-31.1,-62.9,-48.2,-49.7,-59.6C-36.5,-71.1,-18.3,-76.9,-5.5,-70.3C7.3,-63.8,14.6,-44.9,24.2,-32.9Z"
            transform="translate(20 20)"
          />
        </svg>
      </div>
      <div className="product-done-inner-container">
        <div className="top">
          <div className="hr"></div>
          <h1 className="section-title">{title}</h1>
        </div>
        <div className="section-counter">
          {project_categories.length > 0 &&
            project_categories.map((project_category) => (
              <div className="counter-card" key={project_category.id}>
                <img src={project_category.icon} className="project-icon" />
                <div className="counter-content">
                  <h2 className="counter-count">
                    {project_category.projects_done_count}
                  </h2>
                  <p className="counter-project-title">
                    {project_category.name}
                  </p>
                  <p className="counter-project-description">
                    {project_category.description}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
