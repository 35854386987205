import "./scheduleACall-styles.scss";
import { useToggle } from "../../../hooks/useToggle/useToggle";
export const ScheduleACall = () => {
  const [state, toggle] = useToggle();
  return (
    <div className="c-schedule-a-call">
      <h1 className="schedule-heading">
        Still Have Doubts? No Worries, Give Us A Call! We Are Here To Answer
        Your Queries!
      </h1>
      <button className="schedule-btn" onClick={toggle}>
        Schedule A Call
      </button>
      {state && <IFrameContainer state={state} toggle={toggle} />}
    </div>
  );
};

export const IFrameContainer = ({ state, toggle }) => {
  return (
    <div className="i-frame-container" onClick={toggle}>
      <iframe
        src="https://calendly.com/growwithbizspartan/28-minutes-for-your-brand?month=2024-01"
        title="Iframe Example"
        className="i-frame"
      ></iframe>
    </div>
  );
};
