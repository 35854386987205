import "./aamHeader-styles.scss";

import { useToggle } from "../../../../hooks/useToggle/useToggle";
import { IFrameContainer } from "../../../Global/ScheduleACall/ScheduleACall";

export const AAMHeader = () => {
  const [state, toggle] = useToggle();
  return (
    <div className="c-aam-header">
      <h1 className="aam-header-heading">
        All-In-One Amazon Account Management Services!
      </h1>
      <p className="aam-hourly-rates">
        You focus on the bigger picture, let our virtual assistants deal with
        the day-to-day operations of your Amazon account.{" "}
      </p>
      <button className="aam-header-consultancy-btn" onClick={toggle}>
        Free Consultation
      </button>
      {state && <IFrameContainer state={state} toggle={toggle} />}
    </div>
  );
};
