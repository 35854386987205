import { RiStoreFill } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import { SiSpeedtest } from "react-icons/si";
import { VscOpenPreview } from "react-icons/vsc";

export const ppcManagement = {
  title: "Amazon PPC Management",
  data: {
    header: {
      title: "Maximize Profits with Minimum Work!",
      highlights: [
        "Professional Amazon PPC Specialists",
        "ROI-Focused Campaign Building & Implementation",
        "Boosting traffic and increasing traffic on routine basis",
        "Reduced ACoS yet Bigger Sales",
      ],
    },
    includes_service: {
      title: "Included in Our PPC Service",
      services_card_data: [
        {
          title: "Product Sponsored Ads",
          description:
            "Ever seen an ad on the top and bottom of the search-result page on Amazon? They are called product-sponsored ads. As an Amazon seller, you can profitably benefit from product-sponsored ads. PPC specialists at Biz Spartan create the best PPC campaign to drive optimal conversions. ",
        },
        {
          title: "Brand Sponsored Ads",
          description:
            "As opposed to products, brand-sponsored ads are focused on the brand itself. A brand logo, a customize headline, and three products within the search results – that’s what you need to get your conversion rate going. Our PPC experts utilize your brand’s story, create a catchy headline, and incorporate three of your best-selling products to build an audience with brand-sponsored ads. ",
        },
        {
          title: "Product Display Ads",
          description:
            "Even though this ad-type is available to vendors only, product display ads promote your product underneath competitors’ pages, targeting customer based on their interests. At Biz Spartan, we help vendors get the best out of product display ads through cross-selling on competitors’ pages. ",
        },
        {
          title: "Coupons & Promos",
          description:
            "If you are brand is registered on Amazon Brand Registry (ABR), this ad type is for you. Coupons and Promos work splendidly for underperforming products by offering discounts to customers. Biz Spartan utilizes this approach and devises the strategy to convert sales for your products on all fronts. ",
        },
      ],
    },
    how_it_works: {
      title: "Step-By-Step PPC Process",
      description:
        "Follow are the steps we follow to accomplish PPC management",
      steps: [
        {
          icon: <RiStoreFill />,
          title: "Contact & Consultation",
          description:
            "First, we talk and hash out your business goals and sales expectations. ",
        },
        {
          icon: <TbReportSearch />,
          title: "Research & Planning",
          description:
            "Then, we research and devise a potent Amazon PPC campaign. ",
        },
        {
          icon: <VscOpenPreview />,
          title: "PPC Implementation",
          description:
            "Next, we implement the strategy and monitor its results regularly. ",
        },
        {
          icon: <SiSpeedtest />,
          title: "Results",
          description:
            "Finally, a report will be submitted to you containing all the metrics of our PPC campaign. ",
        },
      ],
    },
    faq: {
      faq_questions: [
        {
          label: "What Type of Services Do You Guys Offer?",
          content:
            "At Biz Spartan, we offer everything Amazon. From listing optimization, keyword ranking, and EBC service to product photography/videography and account audit and management. We also provide Amazon PPC campaign services to give businesses an extra profit boost. ",
        },
        {
          label: "How Do I Make My Product Page Noticeable?",
          content:
            "This is a common question we get here at Biz Spartan. On Amazon, a good product page has all the right ingredients to make the page stand out, which includes high-res product photos, keyword-enriched content (title, listing, product descriptions, etc.), positive reviews, and quick customer support. At Biz Spartan, we help struggling sellers solve such complex factors, ensuring their page ranks on top.  ",
        },
        {
          label: "What Makes A Good Amazon Seller Account?",
          content:
            "A good Amazon seller account follows all the right rules to ensure Amazon guidelines and customer satisfaction. At Biz Spartan, our Amazon audit service will dive deep into your Amazon store and deliver a clear report underlying all the plausible discrepancies you need to work on. ",
        },
        {
          label: "Do You Provide Samples?",
          content:
            "For our product photography and videography services, we provide niche-based samples and past projects that we have worked on (with the seller’s consent) so that you may know about our work.  ",
        },
        {
          label: "Will You Use My Business Information?",
          content:
            "We take special precautions regarding the privacy and confidentiality of our Amazon sellers. Biz Spartan never shares, uses, or sells your information to any third party whatsoever. Any information you may feel comfortable sharing with us will be kept in the strictest confidence. ",
        },
      ],
    },
  },
};
