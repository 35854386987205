import "./contact-form-style.scss";
import { contact_form_data } from "../../../data";
import { useState } from "react";
import axios from "axios";

export const ContactUs = () => {
  const { name, contact_form, social_links, fields_data, button, text_area } =
    contact_form_data;

  const [formState, setFormState] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <section className="c-contactus-section">
        <div className="container">
          <div className="parent-contact-us">
            <div className="grid-items">
              <div className="company_details">
                <h2 className="main_head">{name}</h2>
                <div>
                  <ul className="company_details_parent">
                    {contact_form.more_details.map((data) => {
                      return (
                        <li className="company_details_child">
                          <a id="company_link" href={data.href}>
                            {data.icon}
                          </a>
                          {data.description}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div>
                  <ul className="social_links_parent">
                    {social_links.links.map((data) => {
                      return (
                        <li className="socail_links_child">
                          <a id="social_linking" href={data.href}>
                            {data.icon}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="grid-items">
              <div className="company_contact_form">
                {error && (
                  <div className="error">
                    <p>{error}</p>
                  </div>
                )}
                {success && (
                  <div className="success">
                    <p>{success}</p>
                  </div>
                )}
                {fields_data.fields.map((data) => {
                  return data.field_space === "short" ? (
                    <div className="input_field">
                      <input
                        id="contact_input"
                        type={data.type}
                        placeholder={data.placeholder}
                        name={data.name}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            [e.target.name]: e.target.value,
                          })
                        }
                        value={
                          !!formState[data.name] ? formState[data.name] : ""
                        }
                      />
                    </div>
                  ) : (
                    <div className="input_field">
                      <textarea
                        className="text_area"
                        rows="4"
                        cols="50"
                        placeholder={data.placeholder}
                        name={data.name}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            [e.target.name]: e.target.value,
                          })
                        }
                        value={
                          !!formState[data.name] ? formState[data.name] : ""
                        }
                      ></textarea>
                    </div>
                  );
                })}

                <button
                  className="submit_btn"
                  type={button.type}
                  disabled={
                    loading ||
                    !formState.name ||
                    !formState.email ||
                    !formState.message
                  }
                  onClick={() => {
                    if (!formState.name) {
                      setError("Please provide your name.");
                      return;
                    } else if (!formState.email) {
                      setError("Please provide your email.");
                      return;
                    } else if (!formState.message) {
                      setError("Please provide your message.");
                      return;
                    }
                    setError(null);
                    setLoading(true);
                    axios
                      .post(
                        "https://bizspartan.herokuapp.com/bizspartan/email",
                        {
                          to: formState.email,
                          subject: "An inquiry from bizspartan",
                          text: formState.message,
                          html: `
                            <div>
                            <h3>Username: ${formState.name} <br />
                            ASIN: ${
                              !!formState.ASIN ? formState.ASIN : "N/A"
                            } <br />
                            Message: ${formState.message} <br />
                            </h3>
                            </div
                          
                          `,
                        }
                      )
                      .then((res) => {
                        setLoading(false);
                        if (res.data.status === "success") {
                          setSuccess(
                            "We have received your message, we'll contact you shortly on your provided email. Thank you for contacting us."
                          );
                          setFormState({});
                        }
                      })
                      .catch((e) => {
                        setLoading(false);
                        setError(e.message);
                      });
                  }}
                >
                  {loading ? "Please wait..." : button.name}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
