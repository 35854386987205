import "./AddImage.scss";

export const AddImageSection = ({ imagesSrc }) => {
  return (
    <>
      <section className="c-parentImageSection" style={{ marginTop: "40px" }}>
        <h1
          style={{
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          Here's How Our All-In-One Account Management Works
        </h1>
        <img src={imagesSrc} />
      </section>
    </>
  );
};
