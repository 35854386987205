import "./aboutUsSection-styles.scss";
import SoftwareEngineeringImage from "../../../static/software-engineer.svg";

export const AboutUsSection = () => {
  return (
    <div className="c-about-us-section">
      <div className="right">
        <img src={SoftwareEngineeringImage} className="image" />
      </div>
      <div className="left">
        <h2 className="logo-heading">Biz Spartan</h2>
        <h1 className="tagline-heading">
          Providing Premium Services with Shear Brilliance
        </h1>
        <p className="content">
          Biz Spartan is an Amazon consultancy and service agency that aims to
          provide top-tier solutions for all your Amazon-specific services.
          Being relatively new in the niche market, we passionately strive to
          maintain quality standards and keep our skills self-evident. Our
          experts have devoted their time to Amazon services and are well-versed
          with in-depth knowledge every Amazon enthusiast should know. With
          years of experience, each leads their relative departments to ensure
          every client's requirements are met and no one has to leave
          unsatisfied. Our top-of-the-line but not limited to it services
          include:
          <ul className="about_listing_part">
            <li>Amazon Listing Optimization </li>
            <li>Amazon PPC Management  </li>
            <li>Amazon Account Management </li>
          </ul>
          Biz Spartan aims to make your Amazon store a part of the big league
          stores and a formidable and renowned competitor. We treat your
          business as our own and regard it highly to ensure we meet all your
          needs and the business blooms high and bright!
        </p>
      </div>
    </div>
  );
};
