import { BsCheckLg } from "react-icons/bs";
import ebcportfolio from "../../static/ebc-service-portfolio.webp";
import { TbFriends, TbDeviceDesktop, TbHome, TbPackage } from "react-icons/tb";
import eligibilityImg from "../../static/ebc-feature-right.webp";

export const amazon_a_content_data = {
  amazon_a_content_header: {
    name: "A+Content",
    title: "Enhanced Brand Content / A+ Content Service",
    tagine: "Give us a call and discuss your Amazon EBC / A+ content needs. ",
    get_a_quote: "Get a Quote",
    schedule_call: "Schedule a Call",
    services_content: [
      {
        icon: <BsCheckLg />,
        description: "Unique, Catchy, & Relevant Content",
      },
      {
        icon: <BsCheckLg />,
        description: "Keyword Research ",
      },
      {
        icon: <BsCheckLg />,
        description: "Higher Conversion Rates ",
      },
      {
        icon: <BsCheckLg />,
        description: "Amazon Product Photography Included",
      },
      {
        icon: <BsCheckLg />,
        description: "Amazon Storefront Graphic Design",
      },
    ],
  },
  ebc_ourPortfolio_data: {
    title: "Our Case Studies",
    name: "Our Portfolio",
    description:
      "Biz Spartan has worked with numerous Amazon sellers to enhance their conversion rates. Our EBC service targets your product’s unique selling point and comes up with copies that help customers to make informed purchases. Have a look at our past work. ",
    ebcportfolioImg: ebcportfolio,
  },
  how_does_it_work_content: {
    name: "HowDoesItWork",
    title: "Step-By-Step A+ Content Process",
    description:
      "We follow the process that we have listed below for perfect a+ content.",
    steps: [
      {
        id: 1,
        name: "WeGather",
        title: "We Listen",
        icon: <TbFriends />,
        description:
          "Get in touch with us and talk to our customer representatives. Tell them about your requirements and business needs.",
      },
      {
        id: 2,
        name: "WeMake",
        title: "We Understand ",
        icon: <TbDeviceDesktop />,
        description:
          "Next, we understand the nature of your business and product range. We note down pointers to help us create A+ content for your Amazon storefront.",
      },
      {
        id: 3,
        name: "WeDeveloped",
        title: "We Brainstorm",
        icon: <TbHome />,
        description:
          "Then, our team of professional Amazon copywriters and designers conducts a quick brainstorming session and discuss the various way through which we can help your brand. ",
      },
      {
        id: 4,
        name: "WeDeveloped",
        title: "We Deliver",
        icon: <TbPackage />,
        description:
          "Finally, we work on the strategy finalized by the team and deliver you the A+ content your business needs. ",
      },
    ],
  },
  FAQs_section: [
    {
      label: "What Type of Services Do You Guys Offer?",
      content:
        "At Biz Spartan, we offer everything Amazon. From listing optimization, keyword ranking, and EBC service to product photography/videography and account audit and management. We also provide Amazon PPC campaign services to give businesses an extra profit boost. ",
    },
    {
      label: "How Do I Make My Product Page Noticeable?",
      content:
        "This is a common question we get here at Biz Spartan. On Amazon, a good product page has all the right ingredients to make the page stand out, which includes high-res product photos, keyword-enriched content (title, listing, product descriptions, etc.), positive reviews, and quick customer support. At Biz Spartan, we help struggling sellers solve such complex factors, ensuring their page ranks on top.  ",
    },
    {
      label: "What Makes A Good Amazon Seller Account?",
      content:
        "A good Amazon seller account follows all the right rules to ensure Amazon guidelines and customer satisfaction. At Biz Spartan, our Amazon audit service will dive deep into your Amazon store and deliver a clear report underlying all the plausible discrepancies you need to work on. ",
    },
    {
      label: "Do You Provide Samples?",
      content:
        "For our product photography and videography services, we provide niche-based samples and past projects that we have worked on (with the seller’s consent) so that you may know about our work.  ",
    },
    {
      label: "Will You Use My Business Information?",
      content:
        "We take special precautions regarding the privacy and confidentiality of our Amazon sellers. Biz Spartan never shares, uses, or sells your information to any third party whatsoever. Any information you may feel comfortable sharing with us will be kept in the strictest confidence. ",
    },
  ],
  eligibilty_criteria: {
    title: "Optimize Your Amazon Product Listings with Our EBC Service!",
    description:
      "Amazon sellers are always on the lookout for new ways to increase their traffic and product ranking. If there is one thing that makes all of this possible is unique and relevant content. Amazon Enhanced Brand Content, now known as A+ Content, enables Amazon sellers to create catchy graphic designs along with informed content to boost their sales. However, in order to use Amazon A+ content feature, your business needs to be registered with Amazon Brand Registry (ABR). ",
    elligibilty_heading: "Eligibility Criteria",
    eligibilty_description:
      "Amazon A+ Content isn’t accessible to all the sellers. The program is invite-only - you will only be able to create A+ once you’re approved by the Amazon itself.The feature is accessible to these sellers:",
    eligibillty_options_first: "Brand Owners/Vendors.",
    eligibillty_options_second:
      "Sellers who have their name on the Amazon Brand Registry (ABR).",
    why_us_heading: "Why Biz Spartan?",
    why_us_para:
      "We have Amazon experts whose A+ Content service will get you:",
    eligibilityImg: eligibilityImg,
    why_options: [
      {
        options: "Improved Conversion Percentage",
      },
      {
        options: "Informed & Relevant Product Content",
      },
      {
        options: "Attractive Product Listings",
      },
      {
        options: "Improved Brand Equity",
      },
    ],
  },
};
