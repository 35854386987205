import "./aloHeader-styles.scss";
import ALOImage from "../../../../static/amazon-listing-optimization.svg";
import { useToggle } from "../../../../hooks/useToggle/useToggle";
import { IFrameContainer } from "../../../Global/ScheduleACall/ScheduleACall";

export const ALOHeader = () => {
  const [state, toggle] = useToggle();

  return (
    <div className="c-ALOHeader">
      <div className="ALOHeader-wrapper">
        <h1 className="main-heading">
          Drive Your Sales with Amazon Listing Optimization!
        </h1>
        <p className="main-description">
          Amazon is becoming competitive by the day, and if you are behind in
          your product listings, you don’t stand a chance. Get your product
          listings optimized at Biz Spartan.
        </p>
        <div className="ALOHeader-btn">
          <a href="/contact-us" id="get-qoute-btn" className="action-btns">
            Get a Qoute
          </a>
          <a id="schedule-call-btn" className="action-btns" onClick={toggle}>
            Schedule a call
          </a>
          {state && <IFrameContainer state={state} toggle={toggle} />}
        </div>
      </div>
    </div>
  );
};
