import { TbFriends, TbDeviceDesktop, TbHome, TbPackage } from "react-icons/tb";

export const product_ranking_data = {
  product_ranking_header: {
    product_title: "Looking To Rank Top On Amazon?",
    product_tagline: "Get Your Products Ranked In A Week!",
    product_question:
      "Reach millions with top-performing keywords and boost your sales target. Biz Spartan is here to help you organically target your products in relevant search queries. ",
    product_advantages:
      "Our Tri-Rank Strategy makes launches and sales growth achievable in a few simple steps.",
    consultancy: [
      {
        consultancyTagline:
          "Get a FREE consultation with our Amazon specialists today! ",
        get_a_quote: "GET A QUOTE",
        book_a_call: "BOOK A CALL",
      },
    ],
  },
  why_ranking_important: {
    title: "Boost Your Sales with Our Amazon Keyword Ranking Service!",
    description:
      "If you are looking to exceed your ROI goals, product ranking is the way to go. At Biz Spartan, our Amazon experts provide you with the right keywords to boost your products’ ranking on Amazon, helping you achieve your sales target organically. We are experienced and have helped numerous brands establish their brand equity on Amazon. It’s time we establish yours!",
    question: "Want to learn more about our Ranking Strategy?",
    videoBtn: "Watch Videos",
  },
  how_does_it_work_content: {
    name: "HowDoesItWork",
    title: "Keyword Ranking Process!",
    description: "",
    steps: [
      {
        id: 1,
        name: "WeGather",
        title: "Initial Discussion",
        icon: <TbFriends />,
        description:
          "First, we’ll have the initial discussion, where you’ll be asked to tell us your requirements and expectations. ",
      },
      {
        id: 2,
        name: "WeMake",
        title: "Planning ",
        icon: <TbDeviceDesktop />,
        description:
          "Once we are done with the discussion, our Amazon experts will conduct the needed research on your business and its product, finding the best-performing keywords.",
      },
      {
        id: 3,
        name: "WeDeveloped",
        title: "Execution",
        icon: <TbHome />,
        description:
          "After compiling the list of keywords, we’ll adjust them accordingly to the content and begin the ranking process. ",
      },
      {
        id: 4,
        name: "WeDeveloped",
        title: "Results",
        icon: <TbPackage />,
        description:
          "Our Amazon experts will work on the keyword ranking of your Amazon products, ensuring the desired rankings of your products. ",
      },
    ],
  },
  FAQs_section: [
    {
      label: "What Type of Services Do You Guys Offer?",
      content:
        "At Biz Spartan, we offer everything Amazon. From listing optimization, keyword ranking, and EBC service to product photography/videography and account audit and management. We also provide Amazon PPC campaign services to give businesses an extra profit boost. ",
    },
    {
      label: "How Do I Make My Product Page Noticeable?",
      content:
        "This is a common question we get here at Biz Spartan. On Amazon, a good product page has all the right ingredients to make the page stand out, which includes high-res product photos, keyword-enriched content (title, listing, product descriptions, etc.), positive reviews, and quick customer support. At Biz Spartan, we help struggling sellers solve such complex factors, ensuring their page ranks on top.  ",
    },
    {
      label: "What Makes A Good Amazon Seller Account?",
      content:
        "A good Amazon seller account follows all the right rules to ensure Amazon guidelines and customer satisfaction. At Biz Spartan, our Amazon audit service will dive deep into your Amazon store and deliver a clear report underlying all the plausible discrepancies you need to work on. ",
    },
    {
      label: "Do You Provide Samples?",
      content:
        "For our product photography and videography services, we provide niche-based samples and past projects that we have worked on (with the seller’s consent) so that you may know about our work.  ",
    },
    {
      label: "Will You Use My Business Information?",
      content:
        "We take special precautions regarding the privacy and confidentiality of our Amazon sellers. Biz Spartan never shares, uses, or sells your information to any third party whatsoever. Any information you may feel comfortable sharing with us will be kept in the strictest confidence. ",
    },
  ],
};
