import "./ppcHeader-styles.scss";
import { IFrameContainer } from "../../../Global/ScheduleACall/ScheduleACall";
import { useToggle } from "../../../../hooks/useToggle/useToggle";

import { AiOutlineCheck } from "react-icons/ai";

export const PPCHeader = ({ headerData }) => {
  const [state, toggle] = useToggle();

  const { title, highlights } = headerData;

  return (
    <div className="c-ppc-header">
      <h1 className="ppc-header-heading">{title}</h1>
      <div className="ppc-service-highlights">
        {highlights &&
          highlights?.map((highlight) => (
            <div className="ppc-service-item">
              <span className="ppc-service-item-icon">
                <AiOutlineCheck />
              </span>
              <p>{highlight}</p>
            </div>
          ))}
      </div>
      <button className="ppc-header-consultancy-btn" onClick={toggle}>
        Free Consultation
      </button>
      {state && <IFrameContainer state={state} toggle={toggle} />}
    </div>
  );
};
