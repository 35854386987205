export const our_services_data = {
  name: "Our Services",
  tagLine: "We are providing outstanding services to our customers",
  content: ` Despite being a prodigy in the eCommerce industry, Biz Spartan has helped numerous businesses take their share of the pie. We utilize our years of experience in concocting a solid strategy for our clients that delivers on all fronts. Take a look at our services:`,
  services: [
    {
      card_serial: "01",
      card_title: "Amazon Product Photography",
      card_description:
        "Visuals matter, and it’s high time you give them the needed attention. Our product photographers know how to bring your product to life with the right angles, lights, colors, and composition.",
      card_link: "/services/amazon-product-photography",
    },
    {
      card_serial: "02",
      card_title: "Amazon Product Videography",
      card_description:
        "Videos have a way of conveying the story to customers. Get our Amazon product videography and attract customers with stellar graphics.",
      card_link: "/services/amazon-product-videography",
    },
    {
      card_serial: "03",
      card_title: "Amazon listing Optimizing",
      card_description:
        "Amazon is one of the toughest eCommerce markets out there. If your listing isn’t optimized, you will be left behind. Biz Spartan offers keyword-rich listing optimization that allows your products to rank at the top.",
      card_link: "/services/amazon-listing-optimization",
    },
    {
      card_serial: "04",
      card_title: "Amazon PPC Management",
      card_description:
        "Our Amazon PPC management brings forth a holistic marketing approach that covers a range of factors, including listing optimization, Amazon Ads, and organic ranking.",
      card_link: "/services/amazon-ppc-management",
    },
    {
      card_serial: "05",
      card_title: "A+ Content/EBC Service",
      card_description:
        "Content sells, and if you are in the business of selling, you need to have content in your arsenal. Biz Spartan offers A+/Enhanced Brand Content service that empowers your product descriptions by targeting its unique selling point.",
      card_link: "/services/ebc-service",
    },
    {
      card_serial: "06",
      card_title: "Amazon Account Management",
      card_description:
        "Our Amazon Account Management service is predicated on the aim to help you with inventory management, order database management, and refund/return cases while you direct your focus on other important business matters.",
      card_link: "/services/amazon-account-management",
    },
    {
      card_serial: "07",
      card_title: "Amazon 3D Rendering",
      card_description:
        "Up the ante with Biz Spartan’s Amazon 3D Rendering service, get realistic product images, and enhance your Amazon marketing approach. We transform your products (existing and non-existing) through our software.",
      card_link: "/services/amazon-3d-ranking",
    },
    {
      card_serial: "08",
      card_title: "Amazon Keyword Ranking",
      card_description:
        "Ranking top on the Amazon page is not an easy feat. However, it’s easy if you are equipped with the right tools and expertise. Our Keyword Ranking service promises to rank your product on the first page.",
      card_link: "/services/amazon-keyword-ranking",
    },
    {
      card_serial: "08",
      card_title: "Amazon Account Audit",
      card_description:
        "With the help of Amazon experts and years of experience in the eCommerce industry, we help clients with our Amazon Account Audit service. Our professionals audit your Amazon account, find flaws, suggest changes, and deliver the audit report for you to look over.",
      card_link: "/services/amazon-account-audit",
    },
  ],
};
