import "./howWeWork-styles.scss";
import { TbHeartHandshake, TbReportSearch, TbPackage } from "react-icons/tb";

export const HowWeWork = () => {
  return (
    <div className="c-how-we-work">
      <div className="we-work-wrapper">
        <div className="we-work-card">
          <div className="icon">
            <TbReportSearch />
          </div>
          <div className="title">
            <h1>We Commit</h1>
          </div>
          <div className="content">
            <p>
              Our team of specialists is committed to producing the best results
              in order to make your product a success.
            </p>
          </div>
        </div>
        <div className="we-work-card">
          <div className="icon">
            <TbHeartHandshake />
          </div>
          <div className="title">
            <h1>We Commit</h1>
          </div>
          <div className="content">
            <p>
              Our team of specialists is committed to producing the best results
              in order to make your product a success.
            </p>
          </div>
        </div>
        <div className="we-work-card">
          <div className="icon">
            <TbPackage />
          </div>
          <div className="title">
            <h1>We Commit</h1>
          </div>
          <div className="content">
            <p>
              Our team of specialists is committed to producing the best results
              in order to make your product a success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
