import "./fixedBackground-styles.scss";

/* 
props 

data:{
  image:"imageUrl"   // background image
  content:"COntent to be add in this fixed backgroudn"
}

*/

export const FixedBackground = ({ data }) => {
  if (!data.image) return;
  return (
    <div
      className="c-fixed-background"
      style={{
        backgroundImage: `url('${data.image}'),linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9))`,
      }}
    >
      <h1 className="background-content">{data.content && data.content}</h1>
    </div>
  );
};
