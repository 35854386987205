import "./packages-style.scss";

export const Packages = ({ title, packages_name }) => {
  console.log("packages_name", packages_name);
  return (
    <section className="c_packages_section">
      <div className="parent_head_section">
        <div className="header">
          <div className="left">
            <div className="hr"></div>
            <h2 id="heading">{title}</h2>
          </div>
        </div>
      </div>
      <div className="parent_packages_section">
        {packages_name.length > 0 &&
          packages_name.map((data) => {
            console.log("data", data.packages_list);
            return (
              <div className="packages_card">
                <div className="package_name">
                  <h2 className="package_title">{data.title}</h2>
                </div>
                <div className="package_lists">
                  <ul className="parent_list">
                    {data.packages_list.map((list) => {
                      return (
                        <li>
                          {list.icon}{" "}
                          <span className="list_data">{list.description}</span>{" "}
                        </li>
                      );
                    })}

                    <div className="get_a_quote">
                      <a href="/contact-us" className="quote">
                        {data.getQuote}
                      </a>
                    </div>
                  </ul>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};
