import { FIELDS_CONTENT_TYPE } from "../../../../data";
import "./optimizationAuditProductForm-styles.scss";
import { Input, TextArea } from "../../../Global/Fields/Fields";
import { useState } from "react";
import axios from "axios";

export const OptimizationAuditProductForm = ({ formData }) => {
  const { form_title, form_description, form_fields } = formData;

  const [formState, setFormState] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div className="c-optimization-audit-product-form">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          console.log(formState);
          {
            if (!formState.ASIN) {
              setError("Please provide URL or ASIN.");
              return;
            } else if (!formState.email) {
              setError("Please provide your email.");
              return;
            } else if (!formState.message) {
              setError("Please provide your message.");
              return;
            }
            setError(null);
            setLoading(true);
            axios
              .post("https://bizspartan.herokuapp.com/bizspartan/email", {
                to: formState.email,
                subject: "Amazon Optimization Audit Request from bizspartan",
                text: formState.message,
                html: `
                            <div>
                            <h3>Product URL or ASIN: ${
                              !!formState.ASIN ? formState.ASIN : "N/A"
                            } <br />
                            Company: ${
                              !!formState.company ? formState.company : "N/A"
                            } <br />
                            Message: ${formState.message} <br />
                            </h3>
                            </div
                          
                          `,
              })
              .then((res) => {
                setLoading(false);
                if (res.data.status === "success") {
                  setSuccess(
                    "We have received your message, we'll contact you shortly on your provided email. Thank you for contacting us."
                  );
                  setFormState({});
                }
              })
              .catch((e) => {
                setLoading(false);
                setError(e.message);
              });
          }
        }}
      >
        <h4 className="form-heading">{form_title}</h4>
        <p className="form-description">{form_description}</p>
        {error && (
          <div className="error">
            <p>{error}</p>
          </div>
        )}
        {success && (
          <div className="success">
            <p>{success}</p>
          </div>
        )}
        <div className="form-fields">
          {!!form_fields.length &&
            form_fields.map((formField) => {
              return (
                <div
                  className="form-control"
                  style={{
                    gridColumn: `span ${formField.col_span}`,
                  }}
                >
                  <label className="form-label">{formField.label}</label>
                  {formField.field_content_type ===
                    FIELDS_CONTENT_TYPE.SHORT && (
                    <Input
                      fieldSetting={{
                        ...formField.field_setting,
                        value: !!formState[formField.field_setting.name]
                          ? formState[formField.field_setting.name]
                          : "",
                        onChange: (e) =>
                          setFormState({
                            ...formState,
                            [e.target.name]: e.target.value,
                          }),
                      }}
                    />
                  )}
                  {formField.field_content_type ===
                    FIELDS_CONTENT_TYPE.LONG && (
                    <TextArea
                      fieldSetting={{
                        ...formField.field_setting,
                        value: !!formState[formField.field_setting.name]
                          ? formState[formField.field_setting.name]
                          : "",
                        onChange: (e) =>
                          setFormState({
                            ...formState,
                            [e.target.name]: e.target.value,
                          }),
                      }}
                      className="form-field"
                    />
                  )}
                </div>
              );
            })}
        </div>
        <div className="submit-btn-container">
          <button
            className="submit-btn"
            disabled={
              loading ||
              !formState.ASIN ||
              !formState.email ||
              !formState.message
            }
          >
            {loading ? "Please wait..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};
