import { Accordion } from "../../../Global/Accordion/Accordion";
import "./faqs-style.scss";

import { product_photography_data } from "../../../../data";

export const Photography_FAQs = () => {
  const { FAQs_section } = product_photography_data;
  return (
    <section className="c_photography_faqs">
      <Accordion accordionData={FAQs_section} />
    </section>
  );
};
